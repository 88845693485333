import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBcG7CG4bqCrm3m-ADwab8YZqz4F0YGC_A",
  authDomain: "reshape2-3c2ac.firebaseapp.com",
  projectId: "reshape2-3c2ac",
  storageBucket: "reshape2-3c2ac.appspot.com",
  messagingSenderId: "1025728825925",
  appId: "1:1025728825925:web:ad05a0ab0ad938add8b12c",
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

if (window.location.hostname === "localhost") {
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.functions().useEmulator("localhost", 5001);
  firebase.storage().useEmulator("localhost", 9199);
}

const db = firebase.firestore();

export { firebase, db };
