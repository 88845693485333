import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  border-right: 1px osli #ddd;
  width: 270px;
  height: 100vh;

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }

  > hr {
    border: 1px solid #ddd;
    margin: 20px 0;
  }

  > span {
    text-align: center;
    margin: 40px 20px;
    color: #555;
    font-weight: 400;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  padding: 40px 0 15px 0;

  > img {
    width: 130px;
    display: block;
    margin: 0 auto;
  }
`;

export const LinkWrapper = styled.div`
  padding: 10px 0;

  > .icon__menu {
    background: transparent;
  }

  > span {
    display: block;
    padding: 0 40px 15px 10px;
  }
  .active {
    background-color: #eaf0fb;
    color: #004623;
    height: 40px;
  }
  > div {
    display: flex;
    flex-direction: column;

    > a {
      display: flex;
      align-items: center;
      margin: 3px 0;
      height: 20px;
      font-size: 16px;
      font-weight: 100;
      color: #999;

      > svg {
        margin: 0 15px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        /* background-color: #eaf0fb; */
      }
    }
  }
`;
