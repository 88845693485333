/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Container,
  MainContainer,
  MainContent,
  PhotoContainers,
} from "../../styles/Edit-Style";

import Aside from "../../components/Aside";
import Input from "../../components/Input";
import Button from "../../components/Button";

import { db, firebase } from "../../services/firebase";

import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import camera from "../../assets/camera.svg";

import Modals from "../../components/Modal";
import { TableContainer } from "../../styles/TableStyles";
import { FiEye, FiTrash } from "react-icons/fi";

function Promotion(props) {
  const storage = firebase.storage();

  const [thumbnail, setThumbnail] = useState({ raw: "", preview: "" });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [state, setState] = useState({
    allPromotions: [],
    selectedIndex: "",
    name: "",
    estado: false,
    id: "",
    checked: null,
  });

  const [seePromo, setSeePromo] = useState("");

  useEffect(() => {
    getAllPromos();
  }, []);

  const getAllPromos = async () => {
    const snapshot = await db.collection("promotions").get();

    let promo = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      console.log(promo);

      promo.push({ id, ...data });
    });

    setState({ ...state, allPromotions: promo });
    setLoading2(false);
  };

  function uploadSingleFile(e) {
    if (e.target.files.length) {
      setThumbnail({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  }

  const save = async () => {
    try {
      setLoading(true);
      const uploadImage = storage
        .ref(`/images/promotions/${thumbnail.raw.name}`)
        .put(thumbnail.raw);

      uploadImage.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          toast.error("Erro ao carregar imagem", { hideProgressBar: true });
        },
        function () {
          uploadImage.snapshot.ref
            .getDownloadURL()
            .then(function (downloadURL) {
              db.collection("promotions")
                .add({
                  promo_image_url: downloadURL,
                })
                .then((res) => {
                  let id = res.id;
                  db.collection("promotions")
                    .doc(id)
                    .set(
                      { promo_id: id, estado: state.estado, name: state.name },
                      { merge: true }
                    );
                  setLoading(false);
                  toast.success("Promoção adicionada!", {
                    hideProgressBar: true,
                  });
                  setThumbnail({ preview: "", raw: "" });
                  setState({ ...state, name: "" });
                  getAllPromos();
                  setOpenModal(false);
                });
            });
        }
      );
    } catch (error) {
      toast.error("Erro ao registar promoção.", { hideProgressBar: true });
      setLoading(false);
    }
  };

  const updatePromoState = async (id) => {
    let idd = "";
    for (let index = 0; index < state.allPromotions.length; index++) {
      if (state.allPromotions[index].estado === true) {
        idd = state.allPromotions[index].promo_id;
      }
    }
    const ref = firebase.firestore().collection("promotions").doc(id);
    let estado = "";

    await ref.get().then((doc) => {
      if (doc.exists) {
        estado = doc.data().estado;
      }
    });

    try {
      if (estado === true) {
        toast.info("Essa promoção já está activa.", { hideProgressBar: true });
      } else {
        setLoading2(true);
        await db.collection("promotions").doc(id).update({ estado: true });
        await db.collection("promotions").doc(idd).update({ estado: false });
        toast.success("Promoção activada!", { hideProgressBar: true });
        getAllPromos();
      }
    } catch (error) {
      toast.error("Erro ao activar promoção.", { hideProgressBar: true });
      setLoading2(false);
    }
  };

  const onDelete = async () => {
    db.collection("promotions")
      .doc(state.selectedIndex)
      .delete()
      .then(() => {
        toast.success("Promoção eliminada com sucesso!", {
          hideProgressBar: true,
        });
        getAllPromos();
        setOpenModal2(false);
      })
      .catch((error) => {
        toast.error("Erro ao eliminar ", { hideProgressBar: true });
      });
  };

  return (
    <Container>
      <Modals
        open={openModal2}
        setOpenModal2={setOpenModal2}
        backgroundColor={"#fff"}
        width={30}
        height={35}
        headerText={"Eliminar"}
        onClose={() => setOpenModal2(false)}
        showButons={true}
        onDelete={() => onDelete()}
        children={<h4>Tem certeza que deseja eliminar?</h4>}
      />

      <Modals
        open={openModal3}
        setOpenModal2={setOpenModal3}
        backgroundColor={"#fff"}
        width={30}
        height={35}
        headerText={"Ver promoção"}
        onClose={() => setOpenModal3(false)}
        showButons={false}
      >
        <img
          src={seePromo && seePromo}
          style={{ height: "70%" }}
          alt="Promoção"
        />
      </Modals>
      <MainContainer>
        <Aside />

        <MainContent>
          <div
            className="headers"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <h2>Todas Promoções</h2>
            <Button
              onClick={() => setOpenModal(true)}
              style={{ width: 220, height: 60 }}
            >
              Adicionar Promoção
            </Button>
          </div>

          <div style={{ paddingLeft: 0, paddingRight: 0 }}>
            <TableContainer>
              <table className="no-efectf">
                <thead>
                  <tr className="table-header">
                    <th className="col" style={{ paddingLeft: 10 }}>
                      Nome
                    </th>
                    <th className="col">Estado</th>
                    <th className="col option">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {state.allPromotions.map((promo, index) => (
                    <tr key={promo.promo_id}>
                      <td
                        className="col"
                        style={{ paddingLeft: 10 }}
                        data-label="Nome:"
                      >
                        {promo.name}
                      </td>
                      <td className="col" data-label="description:">
                        {promo.estado === true ? "Activa" : "Desactivada"}
                      </td>
                      <td className="col icon_table">
                        <input
                          type="checkbox"
                          name="test"
                          checked={promo.estado === true ? true : false}
                          onClick={() => {
                            setState({ ...state, id: promo.promo_id });
                            updatePromoState(promo.promo_id);
                          }}
                          style={{ marginRight: 10 }}
                          disabled={loading2 ? true : false}
                        />

                        <FiEye
                          size={16}
                          onClick={() => {
                            setSeePromo(promo.promo_image_url);
                            setOpenModal3(true);
                          }}
                        />

                        <FiTrash
                          size={16}
                          onClick={() => {
                            setState({ ...state, selectedIndex: promo.id });
                            setOpenModal2(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </div>
          <Modals
            open={openModal}
            setOpenModal={setOpenModal}
            backgroundColor={"#fff"}
            width={60}
            height={85}
            headerText={"Adicionar Promoção"}
            onClose={() => setOpenModal(false)}
            showButons={false}
            children={
              <>
                <div>
                  <label>Nome</label>
                  <Input
                    type="text"
                    size={100}
                    space={5}
                    placeholder="Digite o nome"
                    value={state.name}
                    onChange={(e) =>
                      setState({
                        ...state,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PhotoContainers>
                    <label
                      id="thumbnail"
                      style={{
                        backgroundImage: `url(${thumbnail.preview})`,
                        objectFit: "cover",
                      }}
                      className={thumbnail.preview ? "has-thumbnail" : ""}
                    >
                      <input
                        type="file"
                        name="file"
                        id="upload-button"
                        style={{ display: "none" }}
                        onChange={uploadSingleFile}
                      />
                      <img src={camera} alt="Select img" />
                    </label>
                  </PhotoContainers>

                  <Button
                    loading={loading}
                    onClick={save}
                    style={{ height: 50, width: "20%" }}
                  >
                    {loading ? (
                      <FaSpinner color="#FFF" size={14} />
                    ) : (
                      <span>Registar</span>
                    )}
                  </Button>
                </div>
              </>
            }
          />
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default Promotion;
