import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width}%;
  height: ${(props) => props.height}%;
  border: 1px solid transparent;
  border-radius: 4px;
  /* padding: 0 30px; */
  > div {
    padding: 0 40px;
    > label {
      margin: 10px 0;
    }
  }
  .btn_extract {
    width: 68%;
    height: 40px;
    margin: 10px 0;
    border-color: transparent;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: ${colors.green};
    color: #fff;
    outline: none;
    text-align: center;
    align-items: center;
    border-radius: 4px;
    font-size: 15px;
    text-transform: uppercase;
    &:hover {
      opacity: 0.8;
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

export const ModalContent = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.open ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1;

  .header_modal {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #f8f9fb;
    border-bottom: 1px solid #ddd;
    padding: 22px 20px;
    margin-bottom: 10px;
    #button {
      cursor: pointer;
    }
  }
  h4 {
    align-self: center;
    margin: 4% 0 6%;
    font-weight: 400;
  }
`;
