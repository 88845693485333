/* eslint-disable array-callback-return */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useRef } from "react";

import {
  Container,
  MainContainer,
  MainContent,
  MainWrapper,
  FormsAula,
  FormsContent,
  PhotoContainers,
} from "../../styles/Edit-Style";

import Aside from "../../components/Aside";
import Input from "../../components/Input";
import Button from "../../components/Button";

import { db, firebase } from "../../services/firebase";

import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import Select from "react-select";
import camera from "../../assets/camera.svg";

import Modals from "../../components/Modal";
import { FiChevronLeft, FiTrash } from "react-icons/fi";
import { TableContainer } from "../../styles/TableStyles";
import { useHistory } from "react-router";

function EditAula(props) {
  const storage = firebase.storage();
  let history = useHistory();

  let day = [];
  let hour = [];

  const [thumbnail, setThumbnail] = useState({ preview: "", raw: "" });
  const [loading, setLoading] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const selectInputRef = useRef();

  const [allEmployees, setAllEmployees] = useState([]);
  const [hours, setHours] = useState([{ begin: "", end: "", instrutor: "" }]);

  const options = [
    { value: "Segunda", label: "Segunda" },
    { value: "Terça", label: "Terça" },
    { value: "Quarta", label: "Quarta" },
    { value: "Quinta", label: "Quinta" },
    { value: "Sexta", label: "Sexta" },
    { value: "Sábado", label: "Sábado" },
    { value: "Domingo", label: "Domingo" },
  ];

  const [classes, setClasses] = useState({
    name: "",
    days: {},
    description: "",
    vagas_disponiveis: "",
    vagas_reservadas: "",
    class_id: "",
    instrutor_id: "",
    dayOfWeek: "",
    deleting: false,
    class_name: "",
    instrutor_name: "",
  });

  useEffect(() => {
    getallEmployees();
    getClasses();
  }, []);

  const getClasses = async () => {
    const query = new URLSearchParams(props.location.search);
    let id = query.get("id");
    const ref = firebase.firestore().collection("aulas").doc(id);

    ref.get().then((doc) => {
      if (doc.exists) {
        setClasses({
          ...classes,
          name: doc.data().name,
          description: doc.data().description,
          vagas_disponiveis: doc.data().vagas_number,
          /*  instrutor_name: {
            value: doc.data().instrutor_id,
            label: doc.data().instrutor,
          }, */
          class_name: doc.data().turma,
          days: doc.data().days,
          url: doc.data().url,
          // instrutor_id: doc.data().instrutor_id,
          // class_id: doc.data().turma_id,
        });
        setThumbnail({ ...thumbnail, preview: doc.data().url });
      }
    });
  };

  const handleChangeData = (e, index) => {
    const { name, value } = e.target;
    const list = [...hours];
    list[index][name] = value;
    setHours(list);
  };
  const addClick = () => {
    setHours([...hours, { begin: "", end: "" }]);
  };
  const removeClick = (i) => {
    let values = [...hours];
    values.splice(i, 1);
    setHours(values);
  };

  const getallEmployees = async () => {
    const snapshot = await db
      .collection("instrutor")
      .where("typeToCheck", "array-contains", "Instrutor")
      .get();

    let employees = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();
      employees.push({ id, ...data });
    });
    setAllEmployees(employees);
  };

  const onEmployeeSelected = (e) => {
    const { name, value } = e.target;

    let instrutor_id = 0;

    for (let i in allEmployees) {
      if (allEmployees[i].name === value) {
        instrutor_id = allEmployees[i].id;
        break;
      }
    }

    setClasses({
      ...classes,
      instrutor_id: instrutor_id,
      instrutor_name: value,
    });
  };

  function uploadSingleFile(e) {
    if (e.target.files.length) {
      setThumbnail({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  }

  const mergeAll = () => {
    const { dayOfWeek } = classes;

    if (!dayOfWeek || !hours[0].instrutor || !hours[0].begin || !hours[0].end) {
      toast.warning("Por Favor, preencha todos os campos", {
        hideProgressBar: true,
      });
      setLoading(false);
    } else {
      day.push(dayOfWeek.value);
      hour.push(hours);

      for (let i in day) {
        classes.days[day[i]] = [];
      }

      for (let j in day) {
        classes.days[day[j]] = hour[j];
      }
      setClasses({ ...classes, dayOfWeek: "" });
      setHours([{ begin: "", end: "", instrutor: "" }]);
      selectInputRef.current.select.clearValue();
    }
  };

  const onDelete = (data, position) => {
    delete classes.days[`${data}`];

    setClasses({ ...classes, deleting: true });
  };

  const saveClass = async () => {
    const {
      name,
      description,
      days,
      vagas_disponiveis,
      class_name,
      instrutor_name,
    } = classes;

    const query = new URLSearchParams(props.location.search);
    let id = query.get("id");
    const ref = db.collection("aulas").doc(id);

    if (!name || !description || !days || !vagas_disponiveis) {
      toast.warning("Por Favor, preencha todos os campos", {
        hideProgressBar: true,
      });
    } else {
      setLoading(true);
      try {
        const starsRef = storage.ref(`/images/${thumbnail.raw.name}`);

        starsRef
          .getDownloadURL()
          .then(function (url) {
            ref
              .update({
                url: classes.url,
                name: classes.name.trim(),
                description: classes.description,
                days: classes.days,
                vagas_number: classes.vagas_disponiveis,
                daysWithoutHour: Object.keys(days)
                // turma_id: classes.class_id,
                /*  instrutor_id: classes.instrutor_name.value,
                instrutor: classes.instrutor_name.label, */
              })

              .then(() => {
                setLoading(false);
                toast.success("Aula editada!", { hideProgressBar: true });
                history.goBack();
                setClasses({
                  name: "",
                  hours: [{}],
                  dayOfWeek: "",
                  days: {},
                  description: "",
                  vagas_disponiveis: "",
                  vagas_reservadas: "",
                  class_id: "",
                  instrutor_id: "",
                  instrutor_name: "",
                });
                setThumbnail({ preview: "", raw: "" });
                selectInputRef.current.select.clearValue();
              });
          })
          .catch(function (error) {
            switch (error.code) {
              case "storage/object-not-found":
                const uploadTask = storage
                  .ref(`/images/${thumbnail.raw.name}`)
                  .put(thumbnail.raw);
                uploadTask.on(
                  "state_changed",
                  (snapShot) => {},
                  (err) => {},
                  () => {
                    storage
                      .ref("images")
                      .child(thumbnail.raw.name)
                      .getDownloadURL()
                      .then((fireBaseUrl) => {
                        ref
                          .update({
                            url: fireBaseUrl,
                            name: classes.name.trim(),
                            description: classes.description,
                            days: classes.days,
                            vagas_number: classes.vagas_disponiveis,
                            daysWithoutHour: Object.keys(days)
                            // turma_id: classes.class_id,
                            /*  instrutor_id: classes.instrutor_name.value,
                            instrutor: classes.instrutor_name.label, */
                          })

                          .then(() => {
                            setLoading(false);
                            toast.success("Aula editada!", {
                              hideProgressBar: true,
                            });
                            history.goBack();
                            setClasses({
                              name: "",
                              hours: [{}],
                              dayOfWeek: "",
                              days: {},
                              description: "",
                              vagas_disponiveis: "",
                              vagas_reservadas: "",
                              class_id: "",
                              instrutor_id: "",
                              instrutor_name: "",
                            });
                            setThumbnail({ preview: "", raw: "" });
                            selectInputRef.current.select.clearValue();
                          });
                      });
                  }
                );
                break;
              case "storage/unauthorized":
                break;
              case "storage/canceled":
                break;
              case "storage/unknown":
                break;
            }
          });
      } catch (err) {
        toast.error("Erro ao Editar!", { hideProgressBar: true });
        setLoading(false);
      }
    }
  };

  let instrutors = allEmployees.map(function (employee) {
    return { value: employee.id, label: employee.name };
  });

  return (
    <Container>
      <MainContainer>
        <Aside />

        <MainContent>
          <MainWrapper>
          <div className="header">
              <button onClick={()=>history.goBack()}>
                <FiChevronLeft size={20} color="#1c1c1c" />
              </button>
              <h1>Editar Aula</h1>
            </div>

            <div className="showPhoto">
              <PhotoContainers>
                <label
                  id="thumbnail"
                  style={{ backgroundImage: `url(${thumbnail.preview})` }}
                  className={thumbnail.preview ? "has-thumbnail" : ""}
                >
                  <input
                    type="file"
                    name="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={uploadSingleFile}
                  />
                  <img src={camera} alt="Select img" />
                </label>
              </PhotoContainers>
            </div>

            <FormsAula>
              <PhotoContainers style={{ width: 235 }} className="photos">
                <label
                  id="thumbnail"
                  style={{ backgroundImage: `url(${thumbnail.preview})` }}
                  className={thumbnail.preview ? "has-thumbnail" : ""}
                >
                  <input
                    type="file"
                    name="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={uploadSingleFile}
                  />
                  <img src={camera} alt="Select img" />
                </label>
              </PhotoContainers>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                }}
                className="formsResh"
              >
                <div className="rights" style={{ width: "45%" }}>
                  <label style={{ display: "block", marginBottom: 25 }}>
                    Nome da Aula{" "}
                  </label>
                  <Input
                    type="text"
                    placeholder="Digite o nome"
                    value={classes.name}
                    size={50}
                    onChange={(e) =>
                      setClasses({ ...classes, name: e.target.value })
                    }
                  />
                </div>

                <aside className="right" style={{ width: "45%" }}>
                  <label style={{ display: "block", marginBottom: 25 }}>
                    Vagas disponíveis
                  </label>
                  <Input
                    placeholder="Digite o número de vagas disponíveis"
                    type="number"
                    value={classes.vagas_disponiveis}
                    size={150}
                    onChange={(e) =>
                      setClasses({
                        ...classes,
                        vagas_disponiveis: e.target.value,
                      })
                    }
                  />
                </aside>
              </div>
            </FormsAula>

            <div
              style={{
                width: "69%",
                display: "flex",
                alignSelf: "flex-end",
                flexDirection: "column",
                marginTop: "-80px",
              }}
              className="descriptions"
            >
              <label>Descrição</label>
              <textarea
                style={{
                  borderWidth: 1,
                  borderColor: "#ddd",
                  height: 90,
                  padding: 20,
                  outline: "none",
                  marginTop: 20,
                  marginBottom: 20,
                  marginRight: 20,
                }}
                name="description"
                value={classes.description}
                onChange={(e) =>
                  setClasses({ ...classes, description: e.target.value })
                }
              ></textarea>
              <Button
                type="button"
                style={{
                  width: "98%",
                  height: 45,
                  marginBottom: 20,
                  background: "#fff",
                  borderColor: "#04d361",
                  borderWidth: 1,
                  color: "#04d361",
                }}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                {Object.keys(classes.days).length !== 0
                  ? "Horário"
                  : " Adicionar Horário"}
              </Button>
            </div>
            <div
              style={{
                paddingRight: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                loading={loading}
                onClick={() => saveClass()}
                style={{ width: 220, height: 50 }}
              >
                {loading ? (
                  <FaSpinner color="#FFF" size={14} />
                ) : (
                  <span>Salvar</span>
                )}
              </Button>
            </div>
          </MainWrapper>
        </MainContent>
        <Modals
          open={openModal}
          setOpenModal={setOpenModal}
          backgroundColor={"#fff"}
          width={65}
          space={5}
          headerText={"Adicionar Horário"}
          onClose={() => setOpenModal(false)}
          showButons={false}
          children={
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <aside className="left_container" style={{ width: "30rem" }}>
                  <div style={{ marginTop: 60 }}>
                    <label style={{ display: "block", paddingBottom: 10 }}>
                      Dia da semana{" "}
                    </label>

                    <Select
                      ref={selectInputRef}
                      placeholder={"Selecione o dia da semana"}
                      options={options}
                      onChange={(option) => {
                        setClasses({
                          ...classes,
                          dayOfWeek: option,
                        });
                      }}
                    />
                  </div>
                  <Button
                    onClick={() => mergeAll()}
                    style={{ marginTop: 20, width: 120 }}
                  >
                    Adicionar
                  </Button>
                </aside>

                <aside className="right_container">
                  {hours.map((el, i) => (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // marginTop: 15,
                      }}
                    >
                      <div>
                        <label>Hora Início</label>
                        <Input
                          type="time"
                          name="begin"
                          value={el.begin}
                          // size={40}
                          space={5}
                          placeholder="Adicionar Hora"
                          style={{ padding: 8.5 }}
                          onChange={(e) => handleChangeData(e, i)}
                        />
                      </div>

                      <div style={{ marginLeft: 20 }}>
                        <label>Hora Fim</label>
                        <Input
                          type="time"
                          name="end"
                          value={el.end}
                          // size={40}
                          space={5}
                          placeholder="Adicionar Hora"
                          style={{ padding: 8.5, marginleft: 8 }}
                          onChange={(e) => handleChangeData(e, i)}
                        />
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <label>Instrutor</label>
                        <Input
                          placeholder="Selecione o instrutor"
                          size={20}
                          name="instrutor"
                          value={el.instrutor}
                          list="employees"
                          onChange={(e) => handleChangeData(e, i)}
                        />
                        <datalist id="employees">
                          {allEmployees.map((item, key) => (
                            <option key={key} value={item.name} />
                          ))}
                        </datalist>
                      </div>
                      {i === 0 ? (
                        <Button
                          className="addMoreInput"
                          type="button"
                          style={{
                            height: 43,
                            marginLeft: 10,
                            width: 60,
                            marginTop: 30,
                          }}
                          onClick={() => addClick()}
                        >
                          +
                        </Button>
                      ) : (
                        <Button
                          className="addMoreInput"
                          type="button"
                          style={{
                            color: "#ff6666",
                            backgroundColor: "#fff",
                            borderColor: "#ff6666",
                            height: 43,
                            width: 60,
                            marginLeft: 5,
                          }}
                          onClick={() => removeClick(i)}
                        >
                          -
                        </Button>
                      )}
                    </div>
                  ))}
                </aside>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignSelf: "flex-end",
                  flexDirection: "column",
                }}
              >
                <TableContainer>
                  <table className="no-efectf">
                    <thead>
                      <tr className="table-header">
                        <th className="col" style={{ paddingLeft: 10 }}>
                          Dia da semana
                        </th>
                        <th className="col">Horários</th>
                        <th className="col option">Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classes.days &&
                        Object.keys(classes.days).map(function (detail, id) {
                          let hours = classes.days[detail];
                          let newArr = [];
                          hours.map(function (val, index) {
                            newArr.push(
                              ["" + val.begin, val.end, val.instrutor].join("-")
                            );
                          });
                          return (
                            <tr key={id}>
                              <td className="col" style={{ paddingLeft: 10 }}>
                                {`${detail} `}
                              </td>
                              <td className="col" data-label="">
                                {newArr.join(", ")}
                              </td>
                              <td className="col icon_table">
                                <FiTrash
                                  className="icons"
                                  onClick={() => onDelete(detail, id)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </TableContainer>
                {Object.keys(classes.days).length !== 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "flex-end",
                      paddingRight: 30,
                    }}
                  >
                    <Button
                      onClick={() => setOpenModal(false)}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      Guardar
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          }
        />
      </MainContainer>
    </Container>
  );
}

export default EditAula;
