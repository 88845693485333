/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Container,
  MainContent,
  SearchContainer,
} from "../../styles/List-Styles";

import { db } from "../../services/firebase";
import { FiEye, FiSearch } from "react-icons/fi";

import { TableContainer } from "../../styles/TableStyles";
import { FaCircle, FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import Modals from "../../components/Modal";
import Button from "../../components/Button";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

function Pendentes({ history }) {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [modalAprove, setModalAprove] = useState(false);
  const [docId, setDocId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    selectedIndex: "",
    allPaymentsDone: [],
    search: "",
    estado: true,
    id: "",
    reason: "",
  });

  useEffect(() => {
    searchFunction();
  }, [state.search]);

  const getAllPaymentsDone = async () => {
    try {
      const snapshot = await db
        .collection("pagamentos")
        .where("status", "==", true)
        .get();

      let payments = [];

      snapshot.forEach((doc) => {
        let id = doc.id;
        let data = doc.data();
        payments.push({ id, ...data });
      });

      setState({ ...state, allPaymentsDone: payments, reason: "" });
    } catch (err) {}
  };
  const updateState = () => {
    if (!state.reason) {
      toast.warning("Descreva o motivo", {
        hideProgressBar: true,
      });
    } else {
      setLoading(true);
      db.collection("pagamentos")
        .doc(state.id)
        .update({
          verified: !state.estado,
          reason: state.reason,
          ready: false,
          status: false,
        })
        .then(() => {
          toast.success("Pagamento reprovado!", {
            hideProgressBar: true,
          });
          getAllPaymentsDone();
          setOpenModal(false);
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Erro ao actualizar ", { hideProgressBar: true });
          setLoading(false);
        });
    }

    /*if (!state.reason) {
     toast.warning("Descreva o motivo", {
       hideProgressBar: true,
     })
   } else {
     db.collection("pagamentos")
       .doc(state.id)
       .update({ verified: !state.estado, reason: state.reason, ready: false })
       .then(() => {
         setState({...state, reason: ""})
         state.estado === true ?
           toast.success("Pagamento reprovado!", {
             hideProgressBar: true,
           })
            : toast.success("Pagamento aprovado!", {
             hideProgressBar: true,
           })
         getAllPaymentsDone();
         setOpenModal(false)
       })
       .catch((error) => {
         toast.error("Erro ao actualizar ", { hideProgressBar: true });
       }); 
   }*/
  };

  function handleAprove() {
    setLoading(true);
    db.collection("pagamentos")
      .doc(state.id)
      .update({ verified: !state.estado, reason: "", ready: false })
      .then(() => {
        toast.success("Pagamento aprovado!", {
          hideProgressBar: true,
        });
        getAllPaymentsDone();
        setModalAprove(false);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao actualizar ", { hideProgressBar: true });
        setLoading(false);
      });
  }

  const searchFunction = async () => {
    if (!state.search) {
      getAllPaymentsDone();
    } else {
      let filtered = state.allPaymentsDone.filter(
        (obj) =>
          (obj &&
            obj.name.toLowerCase().indexOf(state.search.toLowerCase()) >= 0) ||
          obj.month.toLowerCase().indexOf(state.search.toLowerCase()) >= 0 ||
          obj.users.forma.toLowerCase().indexOf(state.search.toLowerCase()) >=
            0 ||
          obj.users.data.toLowerCase().indexOf(state.search.toLowerCase()) >=
            0 ||
          obj.value.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
      );
      setState({ ...state, allPaymentsDone: filtered });
    }
  };

  function seeComprovativo(name) {
    window.open(name);
  }

  async function openSecondModal(id) {
    console.log(id);

    const responses = db.collection("pagamentos").doc(id);

    responses.get().then((doc) => {
      if (doc.exists) {
        let user = [];

        user.push(doc.data());
        setDocId(user);

        console.log(user);
      }
    });
    setOpenModal2(true);
  }

  return (
    <Container>
      <Modals
        open={openModal2}
        setOpenModal2={setOpenModal2}
        backgroundColor={"#fff"}
        width={50}
        height={50}
        headerText={"Comprovativos"}
        showButons={false}
        onClose={() => setOpenModal2(false)}
      >
        <>
          <TableContainer>
            <table className="no-efectf">
              <thead>
                <tr className="table-header">
                  <th className="col" style={{ paddingLeft: 10 }}>
                    Nome
                  </th>

                  <th className="col option">Opção</th>
                </tr>
              </thead>
              <tbody>
                {docId &&
                  docId.map((element, index) =>
                    element.fileUrl.map((el, i) => (
                      <tr key={i}>
                        <td
                          className="col"
                          style={{ paddingLeft: 10 }}
                          data-label="Nome:"
                        >
                          Comprovativo-{i}
                        </td>

                        <td className="col icon_table">
                          <FiEye
                            className="icons"
                            title="ver comprovativo"
                            size={18}
                            onClick={() => seeComprovativo(el && el.name)}
                          />
                        </td>
                      </tr>
                    ))
                  )}
              </tbody>
            </table>
          </TableContainer>
        </>
      </Modals>

      <Modals
        open={openModal}
        setOpenModal2={setOpenModal}
        backgroundColor={"#fff"}
        width={35}
        height={50}
        headerText={"Reprovar pagamento"}
        onClose={() => setOpenModal(false)}
        showButons={false}
        // onDelete={() => updateState()}
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <>
                <div style={{ display: "flex", alignSelf: "flex-start" }}>
                  <label>
                    Clique em "sim" para reprovar, após descrever o motivo
                  </label>
                </div>
                <textarea
                  style={{
                    resize: "none",
                    width: "100%",
                    borderWidth: 1,
                    borderColor: "#ddd",
                    height: 95,
                    padding: 20,
                    outline: "none",
                    marginTop: 20,
                    marginBottom: 20,
                    borderRadius: 4,
                  }}
                  placeholder="Descreva o motivo"
                  name="reason"
                  value={state.reason}
                  onChange={(e) =>
                    setState({ ...state, reason: e.target.value })
                  }
                ></textarea>
                <div style={{ display: "flex", alignSelf: "flex-end" }}>
                  <Button
                    loading={loading}
                    onClick={() => {
                      updateState();
                    }}
                    style={{
                      backgroundColor: "#04d361",
                      color: "#fff",
                      border: 0,
                      width: "150px",
                    }}
                  >
                    {loading ? (
                      <FaSpinner color="#FFF" size={14} />
                    ) : (
                      <span>Sim</span>
                    )}
                  </Button>
                </div>
              </>
            </div>
          </>
        }
      />

      <Modals
        open={modalAprove}
        setOpenModal2={setModalAprove}
        backgroundColor={"#fff"}
        width={35}
        height={30}
        headerText={"Aprovar Pagamento"}
        onClose={() => setModalAprove(false)}
        showButons={false}
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <>
                <h4>Clique em "sim" para aprovar</h4>
                <Button
                  loading={loading}
                  onClick={() => {
                    handleAprove();
                  }}
                  style={{
                    backgroundColor: "#04d361",
                    color: "#fff",
                    border: 0,
                    width: "150px",
                  }}
                >
                  {loading ? (
                    <FaSpinner color="#FFF" size={14} />
                  ) : (
                    <span>Sim</span>
                  )}
                </Button>
              </>
            </div>
          </>
        }
      />
      <MainContent style={{ borderTop: 0, width: "100%" }}>
        <div className="headers" style={{ borderTop: 0 }}>
          <h2>Pagamentos efectuados</h2>

          <div className="btnPrints">
            <ReactHtmlTableToExcel
              id="test-table-xls-button"
              className="btn_extract"
              table="table-to-xls"
              filename="extrato"
              sheet="Sheet"
              buttonText="Exportar Dados"
            />
          </div>
        </div>
        <SearchContainer>
          <FiSearch size={15} color="#ccc" />
          <input
            placeholder="Pesquisar..."
            type="text"
            onChange={(e) => setState({ ...state, search: e.target.value })}
          />
        </SearchContainer>
        <div>
          <TableContainer>
            <table className="no-efectf" id="table-to-xls">
              <thead>
                <tr className="table-header">
                  <th className="col" style={{ paddingLeft: 10 }}>
                    Nome
                  </th>
                  <th className="col">Mês</th>
                  <th className="col option">Forma de Pagamento</th>
                  <th className="col option">Data</th>
                  <th className="col option">Comprovativo</th>
                  <th className="col option"></th>
                </tr>
              </thead>
              <tbody>
                {state.allPaymentsDone.map((payment, index) => (
                  <tr key={payment.id}>
                    <td
                      className="col"
                      style={{ paddingLeft: 10 }}
                      data-label="Nome:"
                    >
                      {payment.users && payment.users.name}
                    </td>
                    <td className="col" data-label="Mês:">
                      {payment.users && payment.users.month}
                    </td>
                    <td className="col" data-label="Forma:">
                      {payment.users && payment.users.forma}
                    </td>
                    <td className="col" data-label="Data:">
                      {payment.users && payment.users.data}
                    </td>
                    <td className="col icon_table">
                      <FiEye
                        className="icons"
                        title="ver comprovativo"
                        size={18}
                        onClick={() => openSecondModal(payment && payment.id)}
                      />
                      <FaCircle
                        className="icons"
                        title="Aprovar"
                        color="#0ffa1b"
                        // title={
                        //   payment.verified === true ? "Reprovar" : "Aprovar"
                        // }
                        // color={
                        //   payment.verified === true ? "#0ffa1b" : "#fa2a1b"
                        // }
                        size={18}
                        onClick={() => {
                          setState({
                            ...state,
                            id: payment.id,
                            estado: payment.verified,
                          });
                          setModalAprove(true);
                        }}
                      />

                      <FaCircle
                        className="icons"
                        title="Reprovar"
                        color="#fa2a1b"
                        size={18}
                        onClick={() => {
                          setState({
                            ...state,
                            id: payment.id,
                            estado: payment.verified,
                          });
                          setOpenModal(true);
                        }}
                      />
                    </td>
                    <td className="col">{payment.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </div>
      </MainContent>
    </Container>
  );
}

export default Pendentes;
