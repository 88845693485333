/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import Calendary from "../../components/Calendary";

import { Container, MainContainer } from "./styles";

import { db } from "../../services/firebase";
import { toast } from "react-toastify";

import Modals from "../../components/Modal";

function Home({ history }) {
  const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useState({
    allClasses: [],
    selectedIndex: "",
    search: "",
  });

  const jsUcfirst = (text) => {
    text = text
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return text;
  };

  useEffect(() => {
    searchFunction();
  }, [state.search]);

  const searchFunction = async () => {
    let searchText = jsUcfirst(state.search);

    if (searchText === "") {
      getAllCLasses();
    } else {
      setState({ ...state, allClasses: [] });

      await db
        .collection("aulas")
        .orderBy("name")
        .startAt(searchText)
        .endAt(searchText + "\uf8ff")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setState({ ...state, allClasses: data });
        });
    }
  };

  const getAllCLasses = async () => {
    const snapshot = await db.collection("aulas").get();

    let aula = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      aula.push({ id, ...data });
    });

    setState({ ...state, allClasses: aula });
  };

  const onDelete = async () => {
    db.collection("aulas")
      .doc(state.selectedIndex)
      .delete()
      .then(() => {
        toast.error("Aula eliminada com sucesso!", { hideProgressBar: true });
        searchFunction();
        setOpenModal(false);
      })
      .catch((error) => {
        toast.error("Erro ao eliminar ", { hideProgressBar: true });
      });
  };

  return (
    <>
      <Modals
        open={openModal}
        setOpenModal2={setOpenModal}
        backgroundColor={"#fff"}
        width={30}
        height={35}
        headerText={"Eliminar"}
        onClose={() => setOpenModal(false)}
        showButons={true}
        onDelete={() => onDelete()}
        children={<h4>Tem certeza que deseja eliminar?</h4>}
      />
      <Container>
        <MainContainer>
          <Calendary />
        </MainContainer>
      </Container>
    </>
  );
}

export default Home;
