/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "react-select";

import { FaSpinner } from "react-icons/fa";
import Modals from "../../components/Modal";

import { db, firebase } from "../../services/firebase";
import { FiTrash, FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import { TableContainer } from "../../styles/TableStyles";

import "../../components/Calendary/Calendar.css";
import {
  PhotoContainers,
  Container,
  MainContainer,
} from "../../styles/Edit-Style";
import camera from "../../assets/camera.svg";

import Aside from "../../components/Aside";
import { useHistory } from "react-router-dom";

const Aula = () => {
  const [loading, setLoading] = useState(null);
  let history = useHistory();

  const storage = firebase.storage();

  let day = [];
  let hour = [];

  const [thumbnail, setThumbnail] = useState({ preview: "", raw: "" });
  const [openModal, setOpenModal] = useState(false);

  const selectInputRef = useRef();

  const [allEmployees, setAllEmployees] = useState([]);
  const [hours, setHours] = useState([{ begin: "", end: "", instrutor: "" }]);

  function handleHiddenModal() {
    const modal__wrapper = document.querySelector(".modalWrapper");
    modal__wrapper.style.display = "none";
  }

  const options = [
    { value: "Segunda", label: "Segunda" },
    { value: "Terça", label: "Terça" },
    { value: "Quarta", label: "Quarta" },
    { value: "Quinta", label: "Quinta" },
    { value: "Sexta", label: "Sexta" },
    { value: "Sábado", label: "Sábado" },
    { value: "Domingo", label: "Domingo" },
  ];

  const [classes, setClasses] = useState({
    name: "",
    days: {},
    description: "",
    vagas_disponiveis: "",
    vagas_reservadas: "",
    class_id: "",
    instrutor_id: "",
    dayOfWeek: "",
    deleting: false,
    class_name: "",
    instrutor_name: "",
  });

  const handleChangeData = (e, index) => {
    const { name, value } = e.target;
    const list = [...hours];
    list[index][name] = value;
    setHours(list);
  };
  const addClick = () => {
    setHours([...hours, { begin: "", end: "", instrutor: "" }]);
  };
  const removeClick = (i) => {
    let values = [...hours];
    values.splice(i, 1);
    setHours(values);
  };

  const getallEmployees = async () => {
    const snapshot = await db
      .collection("instrutor")
      .where("typeToCheck", "array-contains", "Instrutor")
      .get();

    let employees = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();
      employees.push({ id, ...data });
    });
    setAllEmployees(employees);
  };

  useEffect(() => {
    getallEmployees();
  }, []);

  function uploadSingleFile(e) {
    if (e.target.files.length) {
      setThumbnail({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  }

  let instrutors = allEmployees.map(function (employee) {
    return { value: employee.id, label: employee.name };
  });

  const mergeAll = () => {
    const { dayOfWeek } = classes;

    if (!dayOfWeek || !hours[0].instrutor || !hours[0].begin || !hours[0].end) {
      toast.warning("Por Favor, preencha todos os campos", {
        hideProgressBar: true,
      });
    } else {
      day.push(dayOfWeek.value);
      hour.push(hours);
      for (let i in day) {
        classes.days[day[i]] = [];
      }

      for (let j in day) {
        classes.days[day[j]] = hour[j];
      }
      setClasses({ ...classes, dayOfWeek: "" });
      setHours([{ begin: "", end: "", instrutor: "" }]);
      selectInputRef.current.select.clearValue();
    }
  };

  const onDelete = (data, position) => {
    delete classes.days[`${data}`];

    setClasses({ ...classes, deleting: true });
  };

  function save() {
    const { name, description, vagas_disponiveis, instrutor_name } = classes;

    db.collection("aulas")
      .add({
        url: "defaut",
        name: classes.name,
        description: classes.description,
        days: classes.days,
        vagas_number: classes.vagas_disponiveis,
      })
      .then(() => {
        setLoading(false);
        toast.success("Aula adicionada!", {
          hideProgressBar: true,
        });
        setClasses({
          name: "",
          hours: [],
          dayOfWeek: "",
          days: {},
          description: "",
          vagas_disponiveis: "",
          vagas_reservadas: "",
          instrutor_id: "",
          instrutor_name: "",
        });
        setThumbnail({ preview: "", raw: "" });
        selectInputRef.current.select.clearValue();
      });
  }

  const saveClass = async () => {
    const { name, description, days, vagas_disponiveis } = classes;

    if (!name || !description || !days || !vagas_disponiveis) {
      toast.warning("Por Favor, preencha todos os campos", {
        hideProgressBar: true,
      });
    } else {
      setLoading(true);
      try {
        const uploadTask = storage
          .ref(`/images/${thumbnail.raw.name}`)
          .put(thumbnail.raw);
        uploadTask.on(
          "state_changed",
          (snapShot) => {},
          (err) => {
            setLoading(false);
            // save();
            toast.error("A imagem da aula não foi carregada");
          },
          () => {
            storage
              .ref("images")
              .child(thumbnail.raw.name)
              .getDownloadURL()
              .then((fireBaseUrl) => {
                db.collection("aulas")
                  .add({
                    url: fireBaseUrl,
                    name: classes.name.trim(),
                    description: classes.description,
                    days: classes.days,
                    vagas_number: classes.vagas_disponiveis,
                    excepcoes: Object.keys(days),
                    daysWithoutHour: Object.keys(days),
                  })
                  .then(() => {
                    setLoading(false);
                    toast.success("Aula adicionada!", {
                      hideProgressBar: true,
                    });
                    setClasses({
                      name: "",
                      hours: [],
                      dayOfWeek: "",
                      days: {},
                      description: "",
                      vagas_disponiveis: "",
                      vagas_reservadas: "",
                      instrutor_id: "",
                      instrutor_name: "",
                    });
                    setThumbnail({ preview: "", raw: "" });
                    selectInputRef.current.select.clearValue();
                  });
              });
          }
        );
      } catch (err) {
        toast.error("Erro ao salvar", { hideProgressBar: true });
        setLoading(false);
      }
    }
  };

  const onEmployeeSelected = (e) => {
    const { name, value } = e.target;

    let instrutor_id = 0;

    for (let i in allEmployees) {
      if (allEmployees[i].name === value) {
        instrutor_id = allEmployees[i].id;
        break;
      }
    }

    setClasses({
      ...classes,
      instrutor_id: instrutor_id,
      instrutor_name: value,
    });
  };

  return (
    <Container>
      <MainContainer>
        <Aside />
        <div className="calendar">
          <div className="modalWrapper">
            <div className="modalContent">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <button
                  style={{ borderWidth: 0, background: "transparent" }}
                  onClick={()=>history.push("/listar-aula")}
                >
                  <FiX size={20} />
                </button>
              </div>
              <PhotoContainers>
                <label
                  id="thumbnail"
                  style={{ backgroundImage: `url(${thumbnail.preview})` }}
                  className={thumbnail.preview ? "has-thumbnail" : ""}
                >
                  <input
                    type="file"
                    name="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={uploadSingleFile}
                  />
                  <img src={camera} alt="Select img" />
                </label>
              </PhotoContainers>

              <div className="forms">
                <aside className="left">
                  <label>Nome</label>
                  <Input
                    placeholder="Nome da Aula"
                    value={classes.name}
                    onChange={(e) =>
                      setClasses({ ...classes, name: e.target.value })
                    }
                  />
                </aside>

                <aside className="right">
                  <label>Vagas disponíveis</label>
                  <Input
                    placeholder="Digite o número de vaga disponível"
                    value={classes.vagas_disponiveis}
                    onChange={(e) =>
                      setClasses({
                        ...classes,
                        vagas_disponiveis: e.target.value,
                      })
                    }
                  />
                </aside>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Descrição da aula</label>

                <textarea
                  style={{
                    borderWidth: 1,
                    borderColor: "#ddd",
                    height: 95,
                    padding: 20,
                    outline: "none",
                    marginTop: 20,
                    marginBottom: 20,
                    borderRadius: 4,
                  }}
                  placeholder="Descreve a aula"
                  name="description"
                  value={classes.description}
                  onChange={(e) =>
                    setClasses({ ...classes, description: e.target.value })
                  }
                ></textarea>
              </div>

              <div className="button_Horario">
                <button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Adicionar Horário
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  loading={loading}
                  onClick={() => saveClass()}
                  style={{ width: 220, height: 50 }}
                >
                  {loading ? (
                    <FaSpinner color="#FFF" size={14} />
                  ) : (
                    <span>Registar</span>
                  )}
                </Button>
              </div>
            </div>
          </div>

          <Modals
            open={openModal}
            setOpenModal={setOpenModal}
            backgroundColor={"#fff"}
            width={70}
            headerText={"Adicionar Horário"}
            onClose={()=>setOpenModal(false)}
            showButons={false}
            children={
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <aside className="lefts" style={{ width: "30rem" }}>
                    <div style={{ marginTop: 60 }}>
                      <label style={{ display: "block", paddingBottom: 10 }}>
                        Dia da semana{" "}
                      </label>

                      <Select
                        ref={selectInputRef}
                        placeholder={"Selecione o dia da semana"}
                        options={options}
                        onChange={(option) => {
                          setClasses({
                            ...classes,
                            dayOfWeek: option,
                          });
                        }}
                      />
                    </div>

                    <Button
                      onClick={() => mergeAll()}
                      style={{ marginTop: 20, width: 120 }}
                    >
                      Adicionar
                    </Button>
                  </aside>

                  <aside className="rights">
                    {hours.map((el, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <label>Hora Início</label>
                          <Input
                            type="time"
                            name="begin"
                            value={el.begin}
                            space={5}
                            style={{ padding: 8.5 }}
                            onChange={(e) => handleChangeData(e, i)}
                          />
                        </div>

                        <div style={{ marginLeft: 20 }}>
                          <label>Hora Fim</label>
                          <Input
                            type="time"
                            name="end"
                            value={el.end}
                            space={5}
                            style={{ padding: 8.5, marginLeft: 5 }}
                            onChange={(e) => handleChangeData(e, i)}
                          />
                        </div>
                        {/* <div className="lefts"> */}

                        <div style={{ marginLeft: 20 }}>
                          <label>Instrutor</label>
                          <Input
                            placeholder="Selecione o instrutor"
                            size={20}
                            name="instrutor"
                            value={el.instrutor}
                            list="employees"
                            onChange={(e) => handleChangeData(e, i)}
                          />
                          <datalist id="employees">
                            {allEmployees.map((item, key) => (
                              <option key={key} value={item.name} />
                            ))}
                          </datalist>
                        </div>
                        {/* </div> */}
                        {i === 0 ? (
                          <Button
                            className="addMoreInput"
                            type="button"
                            style={{
                              height: 43,
                              marginLeft: 10,
                              width: 60,
                              marginTop: 30,
                            }}
                            onClick={() => addClick()}
                          >
                            +
                          </Button>
                        ) : (
                          <Button
                            className="addMoreInput"
                            type="button"
                            style={{
                              color: "#ff6666",
                              backgroundColor: "#fff",
                              borderColor: "#ff6666",
                              height: 43,
                              width: 60,
                              marginLeft: 5,
                            }}
                            onClick={() => removeClick(i)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    ))}
                  </aside>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignSelf: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <TableContainer>
                    <table className="no-efectf">
                      <thead>
                        <tr className="table-header">
                          <th className="col" style={{ paddingLeft: 10 }}>
                            Dia da semana
                          </th>
                          <th className="col">Horários</th>
                          <th className="col option">Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classes.days &&
                          Object.keys(classes.days).map(function (detail, id) {
                            let hours = classes.days[detail];
                            let newArr = [];
                            hours.map(function (val, index) {
                              newArr.push(
                                ["" + val.begin, val.end, val.instrutor].join(
                                  "-"
                                )
                              );
                            });
                            return (
                              <tr key={id}>
                                <td className="col" style={{ paddingLeft: 10 }}>
                                  {`${detail} `}
                                </td>
                                <td className="col" data-label="">
                                  {newArr.join(", ")}
                                </td>
                                <td className="col icon_table">
                                  <FiTrash
                                    className="icons"
                                    onClick={() => onDelete(detail, id)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </TableContainer>

                  {Object.keys(classes.days).length !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        paddingRight: 30,
                      }}
                    >
                      <Button
                        onClick={() => setOpenModal(false)}
                        style={{ marginTop: 20, width: 120 }}
                      >
                        Guardar
                      </Button>
                    </div>
                  ) : null}
                </div>
              </>
            }
          />
        </div>
      </MainContainer>
    </Container>
  );
};
export default Aula;
