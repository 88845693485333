import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.div`
  flex: 1;
`;

export const MainContainer = styled.main`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

export const TabsHeader = styled.div`
  width: calc(100% - 270px);
`;

export const MainContent = styled.div`
  width: calc(100% - 270px);
  background-color: transparent;
  border-top: 1px solid ${colors.borderColor};
  padding: 0 80px;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    padding: 0;
    overflow-x: scroll;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 35px 0 15px 0;
  margin-top: 4rem;

  @media (min-width: 320px) and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }

  div:nth-child(1) {
    border-bottom: 6px solid #04d361;
    color: #04d361;
  }
  div:nth-child(2) {
    border-bottom: 6px solid #ec1414;
    color: #ec1414;
  }
  div:nth-child(3) {
    border-bottom: 6px solid #00aaff;
    color: #00aaff;
  }
  div:nth-child(4) {
    border-bottom: 6px solid #323235;
    color: #323235;
  }
`;

export const Card = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 190px;
  background: #fff;
  border-radius: 4px;
  margin: 0 25px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 310px;
    height: 180px;
    margin: 20px 0;
  }

  > span {
    margin-top: 15px;
  }

  &:first-child {
    margin-left: 0;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }

  > h1 {
    display: block;
    font-size: 18px;
    margin: 30px 0 0 0;
    font-weight: 500;
  }
`;
