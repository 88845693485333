/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Aside from "../../components/Aside";

import {
  Container,
  MainContainer,
  MainContent,
} from "../../styles/List-Styles";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { TabsWrapper } from "./../../styles/TabsWrapper";

import Create from "./List-Aula";
import List from "./AllAula";
import Header from "../../components/Header";

function Aulas() {
  return (
    <>
      <Header />
      <Container>
        <MainContainer>
          <Aside />

          <MainContent>
            <TabsWrapper>
              <Tabs>
                <TabList>
                  <Tab>Todas Aulas</Tab>
                  <Tab>Calendário</Tab>
                </TabList>
                <TabPanel>
                  <List />
                </TabPanel>

                <TabPanel>
                  <Create />
                </TabPanel>
              </Tabs>
            </TabsWrapper>
          </MainContent>

          {/* <TabBar /> */}
        </MainContainer>
      </Container>
    </>
  );
}

export default Aulas;
