/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import dateFns from "date-fns";

import pt from "date-fns/locale/pt";

import Modals from "../../components/Modal";

import { db } from "../../services/firebase";

import { TableContainer } from "../../styles/TableStyles";

import "../Calendary/Calendar.css";
import { toast } from "react-toastify";
import { FiSearch, FiTrash } from "react-icons/fi";

import { SearchContainer } from "../../styles/List-Styles.js";

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [openModal, setOpenModal] = useState(false);

  const [state, setState] = useState({
    allClasses: [],
    selectedIndex: "",
    search: "",
    allRequests: [],
  });

  useEffect(() => {
    if (openModal == true) {
      // setTimeout(() => {
      searchFunction(days);
      // }, 3000);

      console.log("EAD");
    }
  }, [state.search]);

  const header = () => {
    const dateFormat = "MMMM YYYY";
    return (
      <div className="header row ">
        <div className="column col-start">
          <div className="icon" onClick={prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="column col-center">
          <span>{dateFns.format(currentDate, dateFormat, { locale: pt })}</span>
        </div>
        <div className="column col-end">
          <div className="icon" onClick={nextMonth}>
            chevron_right
          </div>
        </div>
      </div>
    );
  };

  const days = () => {
    const dateFormat = "ddd";
    const days = [];
    let startDate = dateFns.startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="column col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat, {
            locale: pt,
          })}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const cells = () => {
    const monthStart = dateFns.startOfMonth(currentDate);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "D";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`column cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => onDateClick(dateFns.parse(cloneDay))}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {" "}
          {days}{" "}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const nextMonth = () => {
    setCurrentDate(dateFns.addMonths(currentDate, 1));
  };

  const prevMonth = () => {
    setCurrentDate(dateFns.subMonths(currentDate, 1));
  };

  const onDateClick = async (day) => {
    setOpenModal(true);
    const daySemana = dateFns.format(day, "DD-MM-YYYY");
    setSelectedDate(daySemana);

    searchFunction(daySemana);

    // newArray.length === 0
    //   ? toast.info("Nenhum Booking para esse dia.", {
    //       hideProgressBar: true,
    //     }) && setOpenModal(false)
    //   : setState({ ...state, allRequests: newArray });
  };

  const getAllRequests = async (days) => {
    const snapshot = await db.collection("booking").get();

    let requests = [];
    let newArray = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      requests.push({ id, ...data });
      newArray = requests.filter(function (el) {
        return el && el.id.includes(days);
      });
    });

    setState({ ...state, allRequests: newArray });

    if (newArray.length === 0) {
      toast.info("Nenhum Pedido disponível.", {
        hideProgressBar: true,
      });
      setOpenModal(false);
    }
  };

  const searchFunction = async (days) => {
    let arr = [];

    if (!state.search) {
      getAllRequests(days);
    } else {
      let filtered = state.allRequests.filter(
        (obj) =>
          obj.pendentes.find(
            (user) =>
              user.name.toLowerCase().indexOf(state.search.toLowerCase()) >=
                0 ||
              user.phone.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
          ) ||
          obj.aulas.name.toLowerCase().indexOf(state.search.toLowerCase()) >=
            0 ||
          obj.data.toLowerCase().indexOf(state.search.toLowerCase()) >= 0 ||
          obj.aulas.end.toLowerCase().indexOf(state.search.toLowerCase()) >=
            0 ||
          obj.aulas.begin.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
      );
      setState({ ...state, allRequests: filtered });
    }
  };

  return (
    <div className="calendar">
      <div>{header()}</div>
      <div>{days()}</div>
      <div>{cells()}</div>
      <Modals
        open={openModal}
        setOpenModal={setOpenModal}
        backgroundColor={"#fff"}
        width={90}
        height={85}
        headerText={`Pendentes do dia : ${selectedDate}`}
        onClose={() => setOpenModal(false)}
        showButons={false}
        className="mobileConfirmation"
        children={
          <>
            <div
              className="search_container"
              style={{
                paddingRight: 55,
                paddingLeft: 40,
                paddingTop: 0,
                paddingBottom: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SearchContainer>
                <input
                  placeholder="Pesquisar aulas..."
                  type="text"
                  onChange={(e) =>
                    setState({ ...state, search: e.target.value })
                  }
                />
              </SearchContainer>
            </div>

            <TableContainer style={{ paddingBottom: 30 }}>
              <table className="no-efectf">
                <thead>
                  <tr className="table-header">
                    <th className="col" style={{ paddingLeft: 10 }}>
                      Nome Completo
                    </th>
                    <th className="col">Telefone</th>
                    <th className="col ">Aula</th>
                    <th className="col ">Dia</th>
                    <th className="col ">Hora de início</th>
                    <th className="col ">Hora de fim</th>
                  </tr>
                </thead>
                <tbody>
                  {state.allRequests &&
                    state.allRequests.map(
                      (element, index) =>
                        element.pendentes &&
                        element.pendentes.map((el, i) => (
                          <>
                            <tr key={el.id}>
                              <td
                                className="col"
                                style={{ paddingLeft: 10 }}
                                data-label="Nome"
                              >
                                {el.name}
                              </td>
                              <td className="col" data-label="Telefone">
                                {el.phone}
                              </td>
                              <td className="col" data-label="Aula">
                                {element.aulas && element.aulas.name}
                              </td>
                              <td className="col" data-label="Dia">
                                {element.aulas && element.aulas.day}
                              </td>
                              <td className="col" data-label="Hora">
                                {element.aulas && element.aulas.begin}
                              </td>
                              <td className="col" data-label="Hora">
                                {element.aulas && element.aulas.end}
                              </td>
                            </tr>
                          </>
                        ))
                    )}
                </tbody>
              </table>
            </TableContainer>
          </>
        }
      />
    </div>
  );
};
export default Calendar;
