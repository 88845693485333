import styled from "styled-components";

export const Container = styled.div`
  display: none;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    background-color: #fff;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    height: 70px;

    padding: 0 10px;
    border-bottom: 1px solid #ddd;

    > a img {
      width: 130px;
    }
    a {
      display: flex;
      align-items: center;
      color: #555;

      > button {
        width: 50px;
        height: 50px;

        border: 0;
        background-color: transparent;
      }

      > span {
        margin-left: -10px;
      }
    }
  }
`;
