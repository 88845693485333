import styled from "styled-components";

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  .react-tabs {
    position: fixed;
    width: 80%;
    height: 100%;

    @media (min-width: 320px) and (max-width: 480px) {
      width: 100%;
      overflow-x: scroll;
      margin-bottom: 6rem;
      background-color: transparent;
    }
  }
  .react-tabs__tab-panel--selected {
    overflow: auto;
    height: 95%;
  }
  .react-tabs__tab-list {
    margin: 0 0 0 0;
    background-color: #f7f8fa;
  }
  > div {
    background-color: #f4f4f4;
    > div {
      padding: 20px;
      background-color: #fff;
    }
  }
  > div ul {
    border-bottom: 0px solid #cf142b;
    > li {
      padding: 10px 15px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border: 0px solid #cf142b;
      /* border-bottom: 2px solid #1c1c1c; */
    }
    .react-tabs__tab {
      margin: 0 5px 0 0;
      background-color: #f7f8fa;
    }
    .react-tabs__tab--selected {
      color: #fff;
      border-color: transparent;
      background-color: red;
    }
  }
`;
