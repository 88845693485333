import styled from "styled-components";

export const TableContainer = styled.div`
  background-color: transparent;
  padding: 20px 0px;
  margin: 10px 0;
  /* height: auto; */
  overflow-y: auto;
  /* scrollbar-width: thin; */
  /* padding-bottom: 50px; */
  table,
  th,
  td {
    border-bottom: 1px solid #ddd;
    border-collapse: collapse;
    font-size: 0.9rem;
    text-align: left;
    font-weight: 300;
  }
  table {
    width: 100%;
    border: 1px solid #ddd;
  }
  .table-header th {
    font-weight: 400;
    text-align: left;
    background-color: #ddd;
  }

  .table-headers th {
    width: 50%;
    font-weight: 400;
    text-align: left;
    background-color: #ddd;
  }
  td,
  th {
    padding: 15px 0;
  }
  td {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 10px 15px 0px;
    background: #fff;
  }
  .icon_table {
    text-align: left;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
    > svg {
      margin: 0 10px;
      &:first-child {
        margin-left: 0;
      }
      @media (min-width: 320px) and (max-width: 480px) {
        display: none;
      }
    }
  }
  .colm {
    padding-left: 10px;
    width: 300px;
  }
  .col_row {
    padding-left: 10px;
  }
  h4 {
    font-weight: 200;
  }
  p {
    font-weight: 100;
  }

  @media all and (max-width: 480px) {
    .table-header {
      display: none;
      border: none;
    }

    th,
    td {
      display: block;
      border: none;
    }
    tr {
      border-bottom: 1px solid #ddd;
      width: 100%;
    }
    .col {
      max-width: 100rem;
    }
    .col {
      display: flex;
      padding: 10px 10px;

      &:before {
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: left;
        font-weight: 500;
      }
    }
  }
`;
