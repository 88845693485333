/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  MainContent,
  SearchContainer,
} from "../../styles/List-Styles.js";
import Modals from "../../components/Modal/index";

import { db } from "../../services/firebase";
import { FiSearch, FiPlusSquare, FiEdit, FiTrash } from "react-icons/fi";
import Select from "react-select";

import { TableContainer } from "../../styles/TableStyles";
import { toast } from "react-toastify";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { FaSpinner } from "react-icons/fa";
import { FormsContent } from "../../styles/Edit-Style";
import { formatMoney } from "../Others/formatMoney";

import NumberFormat from "react-number-format";

function Processados({ history }) {
  let processDate = new Date().toJSON().slice(0, 10);
  const selectInputRef = useRef();

  const [multaValue, setMultaValue] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arrPayment, setArrPayment] = useState([]);
  const [state, setState] = useState({
    selectedIndex: "",
    allUsers: [],
    search: "",
    message: "",
    editing: false,
    deleting: false,
  });
  const [userPayment, setUserPayment] = useState({
    name: "",
    idUser: "",
    month: "",
    value: "",
    status: false,
    dateOf: processDate,
    multa: 0,
    total: 0,
    ready: false,
  });

  const options = [
    { value: "Janeiro", label: "Janeiro" },
    { value: "Fevereiro", label: "Fevereiro" },
    { value: "Março", label: "Março" },
    { value: "Abril", label: "Abril" },
    { value: "Maio", label: "Maio" },
    { value: "Junho", label: "Junho" },
    { value: "Julho", label: "Julho" },
    { value: "Agosto", label: "Agosto" },
    { value: "Setembro", label: "Setembro" },
    { value: "Outubro", label: "Outubro" },
    { value: "Novembro", label: "Novembro" },
    { value: "Dezembro", label: "Dezembro" },
  ];

  useEffect(() => {
    searchFunction();
  }, [state.search]);

  const getAllUsers = async () => {
    const snapshot = await db.collection("users").get();

    let users = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      users.push({ id, ...data });
    });

    setState({ ...state, allUsers: users });
  };

  const mergeAll = async () => {
    const { name, idUser, month, value } = userPayment;

    if (!userPayment.month || !userPayment.value) {
      toast.warning("Por favor, preencha o valor e o mês!", {
        hideProgressBar: true,
      });
    } else {
      console.log(userPayment.value);
      console.log(userPayment.multa);

      const convertValueMonth = userPayment.value.replace(/[^0-9]/g, "");
      const convertValueMulta = userPayment.multa.replace(/[^0-9]/g, "");

      console.log("ConvertValueMulta", convertValueMulta);

      setMultaValue(convertValueMulta);

      const result = parseInt(convertValueMonth) + +convertValueMulta;
      console.log("Total", result);
      const payment = {
        name: name,
        idUser: idUser,
        month: month.value,
        value: value,
        status: userPayment.status,
        dateOf: userPayment.dateOf,
        multa: convertValueMulta,
        total: result,
        ready: userPayment.ready,
        fileUrl: [],
      };
      arrPayment.push(payment);
      selectInputRef.current.select.clearValue();
      setUserPayment({
        name: "",
        idUser: "",
        month: "",
        value: "",
        status: false,
        dateOf: processDate,
        multa: 0,
        total: 0,
        ready: false,
      });
      setState({ ...state, editing: false, deleting: false });
    }
  };

  const seeDetails = (id) => {
    setOpenModal(true);
    const ref = db.collection("users").doc(id);
    ref.get().then((doc) => {
      if (doc.exists) {
        setUserPayment({
          ...userPayment,
          name: doc.data().name,
          idUser: doc.data().uid,
        });
      }
    });
  };

  const paymentMonth = async () => {
    setLoading(true);
    try {
      for (let i in arrPayment) {
        db.collection("pagamentos").add(arrPayment[i]);

        let currentPosition = i;
        let incremented = ++currentPosition;
        const lastElement = arrPayment.length;

        if (incremented === lastElement) {
          toast.success("Pagamento Enviado com sucesso!", {
            hideProgressBar: true,
          });
          setArrPayment([]);
          setLoading(false);
        }
      }
    } catch (err) {
      toast.error("Erro ao enviar pagamento.", { hideProgressBar: true });
      setLoading(false);
    }
  };

  const searchFunction = async () => {
    if (!state.search) {
      getAllUsers();
    } else {
      let filtered = state.allUsers.filter(
        (obj) => obj.name.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
      );
      setState({ ...state, allUsers: filtered });
    }
  };

  const onDelete = (data, position) => {
    if (state.editing === true) {
      toast.warning("Erro. Não deves eliminar enquanto estiveres a editar", {
        hideProgressBar: true,
      });
      return;
    }
    arrPayment.splice(position, 1);
    setState({ ...state, deleting: true });
  };

  const onEdit = (data, position) => {
    setUserPayment({
      name: data.name,
      idUser: data.idUser,
      month: { label: data.month, value: data.month },
      value: data.value,
      status: false,
      dateOf: processDate,
      multa: data.multa,
      total: data.total,
      ready: false,
    });
    arrPayment.splice(position, 1);
    setState({ ...state, editing: true });
  };
  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  let options2 = state.allUsers.map(function (students) {
    return { value: students.id, label: students.name };
  });

  return (
    <Container>
      <MainContent style={{ borderTop: 0, width: "100%" }}>
        <div className="headers" style={{ borderTop: 0 }}>
          <h2>Emitir Pagamentos</h2>
        </div>
        <div className="search_container">
          <SearchContainer style={{ width: "100%" }}>
            <Select
              options={options2}
              styles={styles}
              placeholder={"Selecione o aluno"}
              onChange={(option) => {
                setUserPayment({
                  ...userPayment,
                  name: option.label,
                  idUser: option.value,
                });
              }}
            />
          </SearchContainer>
        </div>
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <div>
            <FormsContent style={{ borderTop: 0, width: "100%" }}>
              <aside className="lefts">
                <label>Nome</label>
                <Input
                  type="text"
                  name="name"
                  size={100}
                  space={5}
                  value={userPayment.name}
                  onChange={(e) =>
                    setUserPayment({
                      ...userPayment,
                      name: e.target.value,
                    })
                  }
                  disabled
                />
                <label>Mês</label>
                <div style={{ marginTop: 23, paddingBottom: 20 }}>
                  <Select
                    ref={selectInputRef}
                    value={userPayment.month}
                    placeholder={"Selecionar o mês"}
                    options={options}
                    onChange={(option) => {
                      setUserPayment({
                        ...userPayment,
                        month: option,
                      });
                    }}
                  />
                </div>
                {/*  <label className="label_total">
                {"Total: " +
                  (+userPayment.value + +userPayment.multa) +
                  "Kz"}
              </label> */}
              </aside>
              <aside className="rights">
                <label>Valor</label>
                <NumberFormat
                  style={{
                    height: 45,
                    width: "100%",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#e6e8eb",
                    paddingLeft: 15,
                    color: "#555",
                    fontSize: 15,
                  }}
                  className="input"
                  value={userPayment.value}
                  thousandSeparator={true}
                  onChange={(e) =>
                    setUserPayment({
                      ...userPayment,
                      value: e.target.value,
                    })
                  }
                />

                <label>Multa</label>
                <NumberFormat
                  style={{
                    height: 45,
                    width: "100%",
                    borderRadius: 4,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#e6e8eb",
                    paddingLeft: 15,
                    color: "#555",
                    fontSize: 15,
                  }}
                  className="input"
                  value={userPayment.multa}
                  thousandSeparator={true}
                  onChange={(e) =>
                    setUserPayment({
                      ...userPayment,
                      multa: e.target.value,
                    })
                  }
                />

                {/* <Input
                  type="number"
                  size={100}
                  space={5}
                  placeholder="Digite o valor da multa"
                  value={userPayment.multa}
                  onChange={(e) =>
                    setUserPayment({
                      ...userPayment,
                      multa: e.target.value,
                    })
                  }
                /> */}
              </aside>
            </FormsContent>
            <div
              className="button_container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button style={{ width: "20%" }} onClick={() => mergeAll()}>
                Adicionar
              </Button>
            </div>
          </div>
          {arrPayment.length === 0 ? null : (
            <div>
              <TableContainer>
                <table className="no-efectf">
                  <thead>
                    <tr className="table-header">
                      <th className="col" style={{ paddingLeft: 10 }}>
                        Nome Completo
                      </th>
                      <th className="col">Mês</th>
                      <th className="col">Valor</th>
                      <th className="col">Multa</th>
                      <th className="col">Total</th>
                      <th className="col option">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrPayment.map((user, index) => (
                      <tr key={user.id}>
                        <td
                          className="col"
                          style={{ paddingLeft: 10 }}
                          data-label="Nome:"
                        >
                          {user.name}
                        </td>
                        <td className="col" data-label="mês:">
                          {user.month}
                        </td>
                        <td className="col" data-label="valor:">
                          {user.value}
                        </td>
                        <td className="col" data-label="multa:">
                          {formatMoney(user.multa)}
                        </td>
                        <td className="col" data-label="total:">
                          {formatMoney(user.total)}
                        </td>

                        <td className="col icon_table">
                          <FiEdit
                            size={16}
                            className="icons"
                            title="Editar"
                            onClick={() => onEdit(user, index)}
                          />
                          <FiTrash
                            size={16}
                            onClick={() => {
                              onDelete(user, index);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableContainer>
              <div
                className="button_container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 20,
                }}
              >
                <Button
                  style={{ width: "20%" }}
                  onClick={() => paymentMonth()}
                  loading={loading}
                >
                  {loading ? (
                    <FaSpinner color="#FFF" size={14} />
                  ) : (
                    <span>Enviar</span>
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
      </MainContent>
    </Container>
  );
}

export default Processados;
