// eslint-disable-next-line no-undef
import React, { useState, useEffect } from 'react';
import { MainContainer, MainContent } from '../../styles/List-Styles';
import { TableContainer } from '../../styles/TableStyles';
import { firebase, db } from '../../services/firebase'

export default function Monitoring() {
    const [TotalMonitoring, setTotalMonitoring] = useState([]);

    function formatarDataParaString(data) {
        let dia = data.getDate();
        let mes = data.getMonth() + 1;
        let ano = data.getFullYear();
        let hora = data.getHours();
        let minutos = data.getMinutes();

        dia = dia < 10 ? '0' + dia : dia;
        mes = mes < 10 ? '0' + mes : mes;
        hora = hora < 10 ? '0' + hora : hora;
        minutos = minutos < 10 ? '0' + minutos : minutos;

        return dia + '-' + mes + '-' + ano + ' (' + hora + ':' + minutos + ')';
    }

    function pegarValidacao(data) {
        let dia = data.getDate();
        let mes = data.getMonth() + 1;
        let ano = data.getFullYear();

        dia = dia < 10 ? '0' + dia : dia;
        mes = mes < 10 ? '0' + mes : mes;

        return dia + '-' + mes + '-' + ano;
    }

    function OrganizarDados(Monitorings) {

        Monitorings.sort((a, b) => {
            if (a.dataDeAgendamento > b.dataDeAgendamento) return -1;
            if (a.dataDeAgendamento < b.dataDeAgendamento) return 1;
            return 0;
        });

        let dados = []

        Monitorings.forEach((data) => {
            if (data.dataDeAgendamento === undefined || data.dataDeAgendamento === null) {
                data.dataDeAgendamento = '--';
            } else if (data.dataDeAgendamento instanceof firebase.firestore.Timestamp) {
                data.dataDeAgendamento = data.dataDeAgendamento.toDate();
                data.dataDeAgendamento = formatarDataParaString(data.dataDeAgendamento);
            }

            if (data.dataDeDesmarcacao === undefined || data.dataDeDesmarcacao === null) {
                data.dataDeDesmarcacao = '--';
            } else if (data.dataDeDesmarcacao instanceof firebase.firestore.Timestamp) {
                data.dataDeDesmarcacao = data.dataDeDesmarcacao.toDate();
                data.dataDeDesmarcacao = formatarDataParaString(data.dataDeDesmarcacao);
            }

            if (data.validacao === undefined || data.validacao === null) {
                data.validacao = '--';
            } else if (data.validacao instanceof firebase.firestore.Timestamp) {
                data.validacao = data.validacao.toDate();
                data.validacao = pegarValidacao(data.validacao);
            }

            dados.push(data);
        });

        return dados
    }

    function getMonitoringTotal() {
        db.collection('monitoring')
            .get()
            .then((querySnapshot) => {
                let Monitorings = [];

                querySnapshot.forEach((doc) => {
                    let id = doc.id;
                    let data = doc.data();

                    Monitorings.push({ id, ...data });
                });

                Monitorings = OrganizarDados(Monitorings);

                setTotalMonitoring(Monitorings);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getMonitoringTotal();
    }, []);

    return (
        <MainContainer>
            <MainContent>
                <div className='headers'>
                    <h2>Monitoração</h2>
                </div>
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <TableContainer>
                        <table className='no-efectf'>
                            <thead>
                                <tr className='table-header'>
                                    <th className='col' style={{ paddingLeft: 10 }} >#</th>
                                    <th className='col' >Aula</th>
                                    <th className='col' >Horário</th>
                                    <th className='col'>Agendamento</th>
                                    <th className='col option'>Desmarcação</th>
                                    <th className='col option'>Aluno</th>
                                    <th className='col option'>Validação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TotalMonitoring ?
                                    TotalMonitoring.map((monitoring, i) => {
                                        return (
                                            <tr key={monitoring.id} border='1px solid #999999'>
                                                <td style={{ paddingLeft: 10 }}>{i + 1}</td>
                                                <td>{monitoring.aula}</td>
                                                <td>{monitoring.inicio}</td>
                                                <td>{monitoring.dataDeAgendamento}</td>
                                                <td>{monitoring.dataDeDesmarcacao ? monitoring.dataDeDesmarcacao : '--'}</td>
                                                <td>{monitoring.aluno}</td>
                                                <td>{monitoring.validacao}</td>
                                            </tr>
                                        );
                                    })
                                    : null}
                            </tbody>
                        </table>
                    </TableContainer>
                </div>
            </MainContent>
        </MainContainer>
    )
}