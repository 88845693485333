/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  MainContent,
  SearchContainer,
} from "../../styles/List-Styles.js";
import Modals from "../../components/Modal/index";

import { db } from "../../services/firebase";
import { FiSearch, FiEdit, FiTrash } from "react-icons/fi";
import { BiBlock } from "react-icons/bi";
import Select from "react-select";

import { TableContainer } from "../../styles/TableStyles";
import { toast } from "react-toastify";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { FaSpinner } from "react-icons/fa";
import { FormsContent } from "../../styles/Edit-Style";
import { formatMoney } from "../Others/formatMoney";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Confirmados({ history }) {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectInputRef = useRef();

  const [state, setState] = useState({
    selectedIndex: "",
    allPayments: [],
    search: "",
    message: "",
    idUser: "",
  });
  const [userPayment, setUserPayment] = useState({
    name: "",
    idUser: "",
    month: "",
    value: "",
    status: false,
    dateOf: "",
    multa: 0,
    total: 0,
  });

  const options = [
    { value: "Janeiro", label: "Janeiro" },
    { value: "Fevereiro", label: "Fevereiro" },
    { value: "Março", label: "Março" },
    { value: "Abril", label: "Abril" },
    { value: "Maio", label: "Maio" },
    { value: "Junho", label: "Junho" },
    { value: "Julho", label: "Julho" },
    { value: "Agosto", label: "Agosto" },
    { value: "Setembro", label: "Setembro" },
    { value: "Outubro", label: "Outubro" },
    { value: "Novembro", label: "Novembro" },
    { value: "Dezembro", label: "Dezembro" },
  ];

  useEffect(() => {
    searchFunction();
  }, [state.search]);

  const getAllPayments = async () => {
    const snapshot = await db
      .collection("pagamentos")
      .where("status", "==", false)
      .get();

    let payment = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      payment.push({ id, ...data });
    });
    setState({ ...state, allPayments: payment });
  };

  const openPaymentToEdit = async (id) => {
    setOpenModal4(true);

    const ref = await db.collection("pagamentos").doc(id);
    ref.get().then((doc) => {
      if (doc.exists) {
        setUserPayment({
          idUser: doc.data().idUser,
          name: doc.data().name,
          month: { value: doc.data().month, label: doc.data().month },
          value: doc.data().value,
          status: doc.data().status,
          dateOf: doc.data().dateOf,
          multa: doc.data().multa,
          total: doc.data().total,
        });
      }
    });
  };

  const editPayment = async () => {
    setLoading(true);
    const { name, idUser, month, value } = userPayment;

    if (!name || !idUser || !month || !value) {
      toast.warning("Por favor, preencha todos os campos!", {
        hideProgressBar: true,
      });
    } else {
      setLoading(true);
      const updateRef = db.collection("pagamentos").doc(state.selectedIndex);
      updateRef
        .update({
          name: name,
          idUser: idUser,
          month: month.value,
          value: value,
          status: userPayment.status,
          dateOf: userPayment.dateOf,
          multa: userPayment.multa,
          total: +userPayment.value + +userPayment.multa,
        })
        .then((doc) => {
          setUserPayment({
            name: "",
            idUser: "",
            month: "",
            value: "",
            status: false,
            dateOf: "",
            multa: 0,
            total: 0,
          });
          selectInputRef.current.select.clearValue();
          setLoading(false);
          toast.success("Pagamento editado!", { hideProgressBar: true });
          setOpenModal4(false);
          getAllPayments();
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Erro ao editar pagamento ", { hideProgressBar: true });
        });
    }
  };

  const searchFunction = async () => {
    if (!state.search) {
      getAllPayments();
    } else {
      let filtered = state.allPayments.filter(
        (obj) =>
          (obj &&
            obj.name.toLowerCase().indexOf(state.search.toLowerCase()) >= 0) ||
          obj.month.toLowerCase().indexOf(state.search.toLowerCase()) >= 0 ||
          obj.total
            .toString()
            .toLowerCase()
            .indexOf(state.search.toLowerCase()) >= 0
      );
      setState({ ...state, allPayments: filtered });
    }
  };

  const onDelete = async () => {
    await db
      .collection("pagamentos")
      .doc(state.selectedIndex)
      .delete()
      .then(() => {
        setOpenModal2(false);
        toast.success("Pagamento eliminado com sucesso!", {
          hideProgressBar: true,
        });
        getAllPayments();
      })
      .catch((error) => {
        toast.error("Erro ao eliminar pagamento ");
      });
  };

  const blockUser = async () => {
    await db
      .collection("users")
      .doc(state.idUser)
      .set(
        {
          estado: false,
        },
        { merge: true }
      )
      .then(() => {
        setOpenModal(false);
        toast.success("Utilizador bloqueado com sucesso!", {
          hideProgressBar: true,
        });
        getAllPayments();
      })
      .catch((error) => {
        toast.error("Erro ao bloquear! ");
      });
  };

  return (
    <Container>
      <Modals
        open={openModal2}
        setOpenModal2={setOpenModal2}
        backgroundColor={"#fff"}
        width={30}
        height={35}
        headerText={"Eliminar"}
        onClose={() => setOpenModal2(false)}
        showButons={true}
        onDelete={() => onDelete()}
        children={<h4>Tem certeza que deseja eliminar?</h4>}
      />
      <Modals
        open={openModal}
        setOpenModal2={setOpenModal}
        backgroundColor={"#fff"}
        width={30}
        height={35}
        headerText={"Bloquear"}
        onClose={() => setOpenModal(false)}
        showButons={true}
        onDelete={() => blockUser()}
        children={<h4>Tem certeza que deseja bloquear?</h4>}
      />

      <Modals
        open={openModal4}
        setOpenModal={setOpenModal4}
        backgroundColor={"#fff"}
        width={40}
        height={65}
        headerText={"Editar pagamento"}
        onClose={() => setOpenModal4(false)}
        showButons={false}
        children={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormsContent>
                <aside className="lefts">
                  <label>Nome</label>
                  <Input
                    type="text"
                    name="name"
                    size={100}
                    space={5}
                    value={userPayment.name}
                    onChange={(e) =>
                      setUserPayment({
                        ...userPayment,
                        name: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label>Mês</label>
                  <div style={{ marginTop: 23 }}>
                    <Select
                      placeholder={"Selecione o mês"}
                      options={options}
                      value={userPayment.month}
                      ref={selectInputRef}
                      onChange={(option) => {
                        setUserPayment({
                          ...userPayment,
                          month: option,
                        });
                      }}
                    />
                  </div>
                  <label>
                    {"Total: " +
                      (+userPayment.value + +userPayment.multa) +
                      "Kz"}
                  </label>
                </aside>
                <aside className="rights">
                  <label>Valor</label>
                  <Input
                    type="number"
                    size={100}
                    space={5}
                    placeholder="Digite o valor a cobrar"
                    value={userPayment.value}
                    onChange={(e) =>
                      setUserPayment({
                        ...userPayment,
                        value: e.target.value,
                      })
                    }
                  />
                  <label>Multa</label>
                  <Input
                    type="number"
                    size={100}
                    space={5}
                    placeholder="Digite o valor da multa"
                    value={userPayment.multa}
                    onChange={(e) =>
                      setUserPayment({
                        ...userPayment,
                        multa: e.target.value,
                      })
                    }
                  />
                </aside>
              </FormsContent>
              <div
                className="button_container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="button"
                  onClick={() => editPayment()}
                  loading={loading}
                >
                  {loading ? (
                    <FaSpinner color="#FFF" size={14} />
                  ) : (
                    <span>salvar Alterações</span>
                  )}
                </Button>
              </div>
            </div>
          </>
        }
      />

      <MainContent style={{ borderTop: 0, width: "100%" }}>
        <div className="headers" style={{ borderTop: 0 }}>
          <h2>Pagamentos processados</h2>

          <div className="btnPrints">
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn_extract"
              table="table-to-xls"
              filename="extrato"
              sheet="Sheet"
              buttonText="Exportar Dados"
            />
          </div>
        </div>

        <div className="search_container">
          <SearchContainer>
            <FiSearch size={15} color="#ccc" />
            <input
              placeholder="Pesquisar..."
              type="text"
              onChange={(e) => setState({ ...state, search: e.target.value })}
            />
          </SearchContainer>
        </div>

        <TableContainer style={{ marginLeft: 20, marginRight: 20 }}>
          <table id="table-to-xls" className="no-efectf">
            <thead>
              <tr className="table-header">
                <th className="col" style={{ paddingLeft: 10 }}>
                  Nome
                </th>
                <th className="col">Mês</th>
                <th className="col option">Valor a pagar</th>
                <th className="col option">Opções</th>
              </tr>
            </thead>
            <tbody>
              {state.allPayments.map((payment, index) => (
                <tr key={payment.id}>
                  <td
                    className="col"
                    style={{ paddingLeft: 10 }}
                    data-label="Nome:"
                  >
                    {payment.name}
                  </td>
                  <td className="col" data-label="Mês">
                    {payment.month}
                  </td>
                  <td className="col" data-label="Valor">
                    {formatMoney(payment.total)}
                  </td>
                  <td className="col icon_table">
                    <BiBlock
                      onClick={() => {
                        setOpenModal(true);
                        setState({ ...state, idUser: payment.idUser });
                      }}
                    />
                    <FiEdit
                      className="icons"
                      title="Editar"
                      onClick={() => {
                        openPaymentToEdit(payment.id);
                        setState({ ...state, selectedIndex: payment.id });
                      }}
                    />
                    <FiTrash
                      className="icons"
                      title="Eliminar"
                      onClick={() => {
                        setOpenModal2(true);
                        setState({ ...state, selectedIndex: payment.id });
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </MainContent>
    </Container>
  );
}

export default Confirmados;
