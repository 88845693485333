/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import dateFns from "date-fns";

import pt from "date-fns/locale/pt";

import Modals from "../../components/Modal";

import { db, firebase } from "../../services/firebase";

import { TableContainer } from "../../styles/TableStyles";

import "./Calendar.css";
import Button from "../Button";
import { toast } from "react-toastify";

const Calendar = () => {
  const [loading, setLoading] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hoursToShow, setHoursToShow] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [dayOf, setDayOf] = useState("");
  const [hours, setHours] = useState([{ begin: "", end: "", instrutor: "" }]);

  const [state, setState] = useState({
    allClasses: [],
    selectedIndex: "",
    search: "",
  });
  let valuesHour = [];

  useEffect(() => {}, []);

  const header = () => {
    const dateFormat = "MMMM YYYY";
    return (
      <div className="header row ">
        <div className="column col-start">
          <div className="icon" onClick={prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="column col-center">
          <span>{dateFns.format(currentDate, dateFormat, { locale: pt })}</span>
        </div>
        <div className="column col-end">
          <div className="icon" onClick={nextMonth}>
            chevron_right
          </div>
        </div>
      </div>
    );
  };

  const days = () => {
    const dateFormat = "ddd";
    const days = [];
    let startDate = dateFns.startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="column col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat, {
            locale: pt,
          })}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const cells = () => {
    const monthStart = dateFns.startOfMonth(currentDate);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "D";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`column cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => onDateClick(dateFns.parse(cloneDay))}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {" "}
          {days}{" "}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const nextMonth = () => {
    setCurrentDate(dateFns.addMonths(currentDate, 1));
  };

  const prevMonth = () => {
    setCurrentDate(dateFns.subMonths(currentDate, 1));
  };

  const seeClasses = async () => {
    setOpenModal(false);

    setLoading(true);

    const snapshot = await db
      .collection("aulas")
      .where("daysWithoutHour", "array-contains", dayOf)
      .get();

    let aula = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();
      aula.push({ id, ...data });

      let arr = [];
      arr.push(dayOf);

      const filtered = Object.keys(data.days)
        .filter((key) => arr.includes(key))
        .reduce((obj, key) => {
          obj[key] = data.days[key];
          return obj;
        }, {});

      let arb = [];
      for (let index = 0; index < Object.values(filtered).length; index++) {
        const element = Object.values(filtered)[index];

        for (let j = 0; j < element.length; j++) {
          const el = element[j];

          arb.push(["" + el.begin, el.end, el.instrutor].join("-"));
        }
        valuesHour.push({
          name: data.name,
          description: data.description,
          hours: arb,
        });
      }
    });
    setHoursToShow(valuesHour);
    setLoading(false);
    aula.length === 0
      ? toast.info("Sem aulas para esse dia.", {
          hideProgressBar: true,
        }) && setOpenModal2(false)
      : setState({ ...state, allClasses: aula });
  };

  const onDateClick = async (day) => {
    setOpenModal(true);
    const daySemana = dateFns.format(day, "dddd", { locale: pt });

    if (daySemana == "segunda-feira") {
      setDayOf("Segunda");
    } else if (daySemana == "terça-feira") {
      setDayOf("Terça");
    } else if (daySemana == "quarta-feira") {
      setDayOf("Quarta");
    } else if (daySemana == "quinta-feira") {
      setDayOf("Quinta");
    } else if (daySemana == "sexta-feira") {
      setDayOf("Sexta");
    } else if (daySemana == "sábado") {
      setDayOf("Sábado");
    } else if (daySemana == "domingo") {
      setDayOf("Domingo");
    }
    /* */
  };

  return (
    <div className="calendar">
      <div>{header()}</div>
      <div>{days()}</div>
      <div>{cells()}</div>
      <Modals
        open={openModal}
        setOpenModal={setOpenModal}
        backgroundColor={"#fff"}
        width={30}
        height={35}
        headerText={"Ver aulas"}
        onClose={() => setOpenModal(false)}
        showButons={false}
        className="mobileConfirmation"
        children={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
              }}
              className="mobileConfirmation"
            >
              <h4>Clique em "sim" para ver as aulas do dia</h4>
              <Button
                onClick={() => {
                  seeClasses();
                  setOpenModal2(true);
                }}
                style={{
                  backgroundColor: "#04d361",
                  color: "#fff",
                  border: 0,
                  width: "150px",
                }}
              >
                Sim
              </Button>
            </div>
          </>
        }
      />
      <Modals
        open={openModal2}
        setOpenModal2={setOpenModal2}
        backgroundColor={"#fff"}
        width={70}
        headerText={"Aulas do dia"}
        onClose={() => {
          setOpenModal2(false);
          setState({ ...state, allClasses: [] });
        }}
        showButons={false}
        children={
          <>
            {loading ? (
              <span style={{ textAlign: "center" }}>
                A carregar as aulas ...
              </span>
            ) : (
              <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                <TableContainer
                  style={{
                    width: "100%",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  <table className="no-efectf">
                    <thead>
                      <tr className="table-header">
                        <th className="col" style={{ paddingLeft: 10 }}>
                          Nome
                        </th>
                        <th className="col">Horários e Instrutor</th>
                        <th className="col">Descrição</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hoursToShow &&
                        hoursToShow.map((classes, index) => (
                          <tr key={classes.id}>
                            <td
                              className="col"
                              style={{ paddingLeft: 10 }}
                              data-label="Nome:"
                            >
                              {classes.name}
                            </td>
                            <td className="col" data-label="hour:">
                              {classes.hours.join(", ")}
                            </td>
                            <td className="col" data-label="description:">
                              {classes.description}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TableContainer>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};
export default Calendar;
