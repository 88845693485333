import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
`;

export const MainContainer = styled.main`
  display: flex;
  width: 100%;
`;

export const TabsHeader = styled.div`
  width: calc(100% - 270px);
`;

export const MainContent = styled.div`
  width: 100%;
  background-color: transparent;
  margin-top: 20px;
`;
