/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import {
  Container,
  MainContainer,
  MainContent,
} from "../../../styles/List-Styles";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { TabsWrapper } from "./../../../styles/TabsWrapper";

import Pendentes from "./Pendentes";
import Tomorrow from "./Confirmado";

function Pedidos() {
  return (
    <div style={{ flex: 1 }}>
      <MainContainer>
        <MainContent>
          <TabsWrapper>
            <Tabs>
              <TabList>
                <Tab>Alunos Agendados</Tab>
                <Tab>Alunos na lista de Espera</Tab>
              </TabList>

              <TabPanel>
                <Tomorrow />
              </TabPanel>

              <TabPanel>
                <Pendentes />
              </TabPanel>
            </Tabs>
          </TabsWrapper>
        </MainContent>
        {/* <TabBar /> */}
      </MainContainer>
    </div>
  );
}

export default Pedidos;
