import React from "react";
import { Switch } from "react-router-dom";

import Login from "../pages/Auth";
import Home from "../pages/Home";
import Aula from "../pages/Register/Aula";

import Funcionarios from "../pages/Funcionarios";
import Alunos from "../pages/Alunos";

import RegisterFuncionario from "../pages/Register/Funcionario";
import EditFuncionario from "../pages/Edit/Funcionario";

import EditAluno from "../pages/Edit/Aluno";
import EditAula from "../pages/Edit/Aula";

import Pagamentos from "../pages/pagamentos";
import PagamentosEfectuados from "../pages/pagamentos/OthersEfect";

import Promotion from "../pages/Promotion";
import Profile from "../pages/Profile";

import ListAula from "../pages/Aula";

import Pedidos from "../pages/Pedido";

import RegistarAula from "../pages/Register/Aula";

import Route from "./routes-config";

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/aula" exact component={Aula} isPrivate />
      <Route path="/registar-aula" exact component={RegistarAula} isPrivate />

      <Route path="/home" component={Home} isPrivate />
      <Route path="/funcionario" component={Funcionarios} isPrivate />
      <Route
        path="/registar-funcionario"
        component={RegisterFuncionario}
        isPrivate
      />
      <Route path="/editar-funcionario" component={EditFuncionario} isPrivate />

      <Route path="/alunos" component={Alunos} isPrivate />
      <Route path="/editar-aluno" component={EditAluno} isPrivate />

      <Route path="/editar-aula" component={EditAula} isPrivate />

      <Route path="/pagamentos" component={Pagamentos} isPrivate />
      <Route
        path="/pagamentos-efectuados"
        component={PagamentosEfectuados}
        isPrivate
      />

      <Route path="/promotion" component={Promotion} isPrivate />

      <Route path="/perfil" component={Profile} isPrivate />

      <Route path="/listar-aula" component={ListAula} isPrivate />
      <Route path="/booking" component={Pedidos} isPrivate />
    </Switch>
  );
}

export default Routes;
