import React, { useState } from "react";

import logo from "../../assets/logo.svg";
import { FaSpinner } from "react-icons/fa";
import { Container, Content, LeftContent, RightContent } from "./styles";

import { firebase } from "../../services/firebase";
import { toast } from "react-toastify";

import Button from "../../components/Button";

function Login({ history }) {
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    email: "",
    password: "",
    message: "",
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const resp = await firebase
        .auth()
        .signInWithEmailAndPassword(state.email, state.password);
      localStorage.setItem("Reshape:token", resp.user.refreshToken);
      localStorage.setItem("Reshape:user", resp.user.uid);
      localStorage.setItem("Reshape:email", state.email);

      setLoading(false);
      history.push("./home");
    } catch (err) {
      toast.error("Ocorreu um erro, verifique os seus dados", {
        hideProgressBar: true,
      });
      setLoading(false);
      setState({ ...state, email: "", password: "" });
    }
  }

  return (
    <Container>
      <Content>
        <LeftContent>
          <img src={logo} alt="Reshape" />

          <span>Preencha os seus dados para continuar!</span>
        </LeftContent>

        <RightContent>
          <h1>Reshape</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              name="email"
              placeholder="Digite seu email"
              onChange={(e) => setState({ ...state, email: e.target.value })}
              value={state.email}
            />

            <label htmlFor="password">Senha</label>
            <input
              type="password"
              name="password"
              placeholder="Digite a sua senha"
              onChange={(e) => setState({ ...state, password: e.target.value })}
              value={state.password}
            />

            <Button loading={loading}>
              {loading ? (
                <FaSpinner color="#FFF" size={14} />
              ) : (
                <span>ENTRAR</span>
              )}
            </Button>

            {/* <a href="#/">Esqueceu sua senha?</a> */}
          </form>
        </RightContent>
      </Content>
    </Container>
  );
}

export default Login;
