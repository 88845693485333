import styled from "styled-components";
import colors from "../styles/colors";

export const Container = styled.div`
  flex: 1;
  @media (min-width: 320px) and (max-width: 480px) {
    padding-top: 5rem;
  }
`;

export const MainContainer = styled.main`
  display: flex;
  /* height: 100vh; */
`;

export const MainContent = styled.div`
  width: 100%;
  background-color: transparent;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    padding: 0;
    overflow-x: scroll;
  }

  > .headers {
    background: white;
    height: 65px;
    padding: 20px 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > a button {
      width: 13rem;
      height: 50px;
      background-color: #4140ea;
    }

    /* border-bottom: 1px solid ${colors.borderColor}; */
  }

  > .search_container {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin: 20px 0;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;

  > input {
    border: none;
    flex: 1;
    background: transparent;
    margin: 0 10px;
  }
`;
