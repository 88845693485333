/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import Button from "../../components/Button";
import Select from "react-select";

import {
  Container,
  MainContainer,
  MainContent,
  SearchContainer,
} from "../../styles/List-Styles.js";

import { db } from "../../services/firebase";

import { TableContainer } from "../../styles/TableStyles";

import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
// import { el } from "date-fns/locale";

function Forms({ history }) {
  const [idForm, setIdForm] = useState("");
  const [five, setFive] = useState([]);
  const [opt, setOptions] = useState({
    first_options: false,
    second_options: false,
    third_options: false,
    four_options: false,
    last_options: false,
  });
  const [formDataUser, setformDataUser] = useState({
    first: "",
    second: "",
    third: "",
    four: "",
  });

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    allInfos: [],
    user_id: 0,
    infos: {},
  });

  const [allStudents, setAllStudents] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllInfos = async () => {
    const snapshot = await db
      .collection("forms")
      .where("user.id", "==", state.user_id)
      .get();

    snapshot.forEach((doc) => {
      let id = doc.id;
      setIdForm(id);
      setformDataUser({
        first: doc.data().first,
        second: doc.data().second,
        third: doc.data().third,
        four: doc.data().four,
      });
      setOptions({
        first_options: doc.data().quinta.includes("Perder Massa gorda"),
        second_options: doc.data().quinta.includes("Ganho de massa muscular"),
        third_options: doc
          .data()
          .quinta.includes(" Melhorar o condicionamento físico"),
        four_options: doc.data().quinta.includes("Aliviar o stress"),
        last_options: doc.data().quinta.includes("Outro"),
      });
    });
  };

  const getAllUsers = async () => {
    const snapshot = await db.collection("users").get();

    let users = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      users.push({ id, ...data });
    });

    setAllStudents(users);
  };

  let options = allStudents.map(function (students) {
    return { value: students.id, label: students.name };
  });

  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const { infos } = state;

  const editForm = async () => {
    setLoading(true);
    try {
      const { first, second, third, four } = formDataUser;
      const {
        first_options,
        second_options,
        third_options,
        four_options,
        last_options,
      } = opt;

      if (first_options == true) {
        five.push("Perder Massa gorda");
      }
      if (second_options == true) {
        five.push("Ganho de massa muscular");
      }
      if (third_options == true) {
        five.push(" Melhorar o condicionamento físico");
      }
      if (four_options == true) {
        five.push("Aliviar o stress");
      }
      if (last_options == true) {
        five.push("Outro");
      }

      const updateRef = await db.collection("forms").doc(idForm);

      updateRef
        .update({
          first: first,
          second: second,
          third: third,
          four: four,
          quinta: five,
        })
        .then((doc) => {
          setformDataUser({
            first: "",
            second: "",
            third: "",
            four: "",
          });
          setFive([]);
          setOptions({
            first_options: false,
            second_options: false,
            third_options: false,
            four_options: false,
            last_options: false,
          });
          toast.success("Formulário Actualizado com sucesso!", {
            hideProgressBar: true,
          });
          getAllInfos();
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Erro ao actualizar formulário!", {
            hideProgressBar: true,
          });

          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Container>
      <MainContainer>
        <MainContent>
          <div className="headers">
            <h2>Formulário</h2>
          </div>

          <div className="search_container">
            <Select
              options={options}
              className="selects"
              styles={styles}
              placeholder={"Selecione o aluno"}
              onChange={(option) => {
                setState({ ...state, user_id: option.value });
              }}
            />

            <div
              style={{ display: "flex", flexDirection: "row" }}
              className="btnForms"
            >
              <Button
                style={{
                  marginLeft: 5,
                  height: 50,
                  backgroundColor: "#00BF6F",
                  color: "#fff",
                  width: "85%",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                type="button"
                onClick={() => getAllInfos()}
              >
                Preencher
              </Button>

              <Button
                style={{
                  marginLeft: 5,
                  height: 50,
                  backgroundColor: "#4140ea",
                  color: "#fff",
                  width: "65%",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                type="button"
                loading={loading}
                onClick={editForm}
              >
                {loading ? (
                  <FaSpinner color="#FFF" size={14} />
                ) : (
                  <span> Actualizar</span>
                )}
              </Button>
            </div>
          </div>

          <div
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className="mobileTables"
          >
            <TableContainer>
              <table className="no-efectf">
                <tr className="table-headers" key={infos.id}>
                  <th className="colm">
                    1- Pratica ou já praticou algum desporto/actividade física.
                  </th>
                  <td className="col_row">
                    <input
                      style={{ width: "100%", border: 0, color: "#555" }}
                      onChange={(e) =>
                        setformDataUser({
                          ...formDataUser,
                          first: e.target.value,
                        })
                      }
                      value={formDataUser.first}
                      //defaultValue={formDataUser.first}
                      // defaultValue={infos.first}
                    />
                  </td>
                </tr>
                <tr className="table-header side">
                  <th className="colm">
                    {" "}
                    2- Qual é a sua actividade física preferida?
                  </th>
                  <td className="col_row">
                    <input
                      style={{ width: "100%", border: 0, color: "#555" }}
                      value={formDataUser.second}
                      // defaultValue={formDataUser.second}
                      onChange={(e) =>
                        setformDataUser({
                          ...formDataUser,
                          second: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr className="table-header">
                  <th className="colm">
                    3- Qual é a actividade física com que não se identifica?
                  </th>
                  <td className="col_row">
                    <input
                      style={{ width: "100%", border: 0, color: "#555" }}
                      onChange={(e) =>
                        setformDataUser({
                          ...formDataUser,
                          third: e.target.value,
                        })
                      }
                      // defaultValue={formDataUser.third}
                      value={formDataUser.third}
                      // defaultValue={infos.third}
                    />
                  </td>
                </tr>
                <tr className="table-header">
                  <th className="colm">
                    {" "}
                    4- Tem alguma restrição, lesão ou patologia que requeira
                    alguma atenção ou possa condicionar certos tipos de
                    exercícios?
                  </th>
                  <td className="col_row">
                    <input
                      style={{ width: "100%", border: 0, color: "#555" }}
                      onChange={(e) =>
                        setformDataUser({
                          ...formDataUser,
                          four: e.target.value,
                        })
                      }
                      // defaultValue={formDataUser.four}
                      value={formDataUser.four}
                      // defaultValue={infos.four}
                    />
                  </td>
                </tr>
                <tr className="table-header">
                  <th className="colm">
                    5 Qual é o seu principal objectivo na pratica da ou das
                    modalidades escolhidas?
                  </th>
                  <td
                    className="col_row"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "100%",
                    }}
                  >
                    {idForm === "" ? null : (
                      <>
                        <div>
                          <input
                            type="checkbox"
                            id="check1"
                            checked={opt.first_options}
                            onChange={() =>
                              setOptions({
                                ...opt,
                                first_options: !opt.first_options,
                              })
                            }
                          />
                          <label for="check1"> {"Perder Massa gorda"}</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="check2"
                            checked={opt.second_options}
                            onChange={() =>
                              setOptions({
                                ...opt,
                                second_options: !opt.second_options,
                              })
                            }
                          />
                          <label for="check2">
                            {" "}
                            {"Ganho de massa muscular"}
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="check3"
                            checked={opt.third_options}
                            onChange={() =>
                              setOptions({
                                ...opt,
                                third_options: !opt.third_options,
                              })
                            }
                          />
                          <label for="check3">
                            {" "}
                            {"Melhorar o condicionamento físico"}
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="check4"
                            checked={opt.four_options}
                            onChange={() =>
                              setOptions({
                                ...opt,
                                four_options: !opt.four_options,
                              })
                            }
                          />
                          <label for="check4"> {"Aliviar o stress"}</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="check5"
                            checked={opt.last_options}
                            onChange={() =>
                              setOptions({
                                ...opt,
                                last_options: !opt.last_options,
                              })
                            }
                          />
                          <label for="check5"> {"Outro"}</label>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              </table>
            </TableContainer>
          </div>
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default Forms;
