import React from "react";

import { Container, MainContainer } from "../../Aula/styles";

import CalendaryBooking from "../../../components/CalendaryBooking/pendentes";

function DayOfTheweek() {
  return (
    <Container>
      <MainContainer style={{ borderTop: 0, width: "100%" }}>
        <CalendaryBooking />
      </MainContainer>
    </Container>
  );
}

export default DayOfTheweek;
