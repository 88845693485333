/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { Container, LogoContainer, LinkWrapper } from "./styles";

import { NavLink } from "react-router-dom";

import {
  FiUsers,
  FiBook,
  FiGrid,
  FiPlusSquare,
  FiUser,
  FiList,
  FiLogOut,
} from "react-icons/fi";

import logo from "../../assets/logo.svg";

import { toast } from "react-toastify";

import { db, firebase } from "../../services/firebase";

import { useHistory } from "react-router-dom";

function Aside() {
  let history = useHistory();
  const [type, setType] = useState(false);

  useEffect(() => {
    getEmployeeLogedIn();
  }, []);

  const Logout = async (e) => {
    e.preventDefault();

    await firebase
      .auth()
      .signOut()
      .then(function () {
        toast.success("Sessão terminada.", { hideProgressBar: true });
        localStorage.removeItem("Reshape:token");
        localStorage.removeItem("Reshape:user");
      })
      .catch(function (error) {
        toast.error("Erro ao terminar sessão", { hideProgressBar: true });
      });

    history.push("/");
  };

  const getEmployeeLogedIn = async () => {
    const id = localStorage.getItem("Reshape:user");

    const snapshot = await db
      .collection("instrutor")
      .where("uid", "==", id)
      .get();

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();
      const result = data && data.typeToCheck.includes("Administrador");

      setType(result);
    });
  };

  return (
    <Container>
      <LogoContainer>
        <img src={logo} alt="Reshape" />
      </LogoContainer>

      <span>Principal</span>
      <LinkWrapper>
        <div>
          <NavLink to="/home">
            <FiGrid />
            Dashboard
          </NavLink>
        </div>
      </LinkWrapper>

      <LinkWrapper>
        <div>
          <NavLink to="/funcionario">
            <FiPlusSquare />
            Funcionários
          </NavLink>
        </div>
      </LinkWrapper>

      <LinkWrapper>
        <div>
          <NavLink to="/alunos">
            <FiUsers />
            Alunos
          </NavLink>
        </div>
      </LinkWrapper>

      <LinkWrapper>
        <div>
          <NavLink to="/registar-aula">
            <FiPlusSquare />
            Registar Aula
          </NavLink>
        </div>
      </LinkWrapper>

      <LinkWrapper>
        <div>
          <NavLink to="/listar-aula">
            <FiBook />
            Gestão de Aulas
          </NavLink>
        </div>
      </LinkWrapper>

      <hr />

      <span>Outros</span>

      <LinkWrapper>
        <div>
          <NavLink to="/pagamentos">
            <FiList />
            Pagamentos
          </NavLink>
        </div>
      </LinkWrapper>

      <LinkWrapper>
        <div>
          <NavLink to="/booking">
            <FiList />
            Pedidos
          </NavLink>
        </div>
      </LinkWrapper>

      <LinkWrapper>
        <div>
          <NavLink to="/promotion">
            <FiPlusSquare />
            Promoção
          </NavLink>
        </div>
      </LinkWrapper>

      <LinkWrapper>
        <div>
          <NavLink to="/perfil">
            <FiUser />
            Perfil
          </NavLink>
        </div>
      </LinkWrapper>

      <LinkWrapper>
        <div onClick={Logout}>
          <NavLink to="/sair">
            <FiLogOut />
            Sair
          </NavLink>
        </div>
      </LinkWrapper>
    </Container>
  );
}

export default Aside;
