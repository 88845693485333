import Global from "./styles/Global";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";

function App() {
  return (
    <>
      <Global />
      <BrowserRouter>
        <Routes />
        <ToastContainer autoClose={3000} />
      </BrowserRouter>
    </>
  );
}

export default App;
