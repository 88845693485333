/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  MainContainer,
  MainContent,
  MainWrapper,
  LeftAside,
  RightAside,
  Forms,
} from "../../styles/Edit-Style";

import Aside from "../../components/Aside";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "react-select";

import { db, firebase } from "../../services/firebase";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { FiChevronLeft } from "react-icons/fi";

function EditFuncionario(props) {
  let history = useHistory();
  const selectInputRef = useRef();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState([]);
  const [employee, setEmployee] = useState({
    name: "",
    phone: "",
    bi: "",
    email: "",
    date_nascimento: "",
    emergency_phone: "",
    profissao: "",
    type: [],
    password: "",
    uid: "",
    typecheck: "",
  });

  useEffect(() => {
    getEmployee();
    getEmployeeLogedIn();
  }, []);

  const getEmployeeLogedIn = async () => {
    const id = localStorage.getItem("Reshape:user");

    const snapshot = await db
      .collection("instrutor")
      .where("uid", "==", id)
      .get();

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();
      const result = data.typeToCheck.includes("Administrador");

      setType(result);
    });
  };

  const backToHome = () => {
    history.push("/funcionario");
  };

  const getEmployee = async () => {
    const query = new URLSearchParams(props.location.search);
    let id = query.get("id");
    const ref = db.collection("instrutor").doc(id);

    ref.get().then((doc) => {
      if (doc.exists) {
        setEmployee({
          name: doc.data().name,
          phone: doc.data().phone,
          bi: doc.data().bi,
          email: doc.data().email,
          date_nascimento: doc.data().date_nascimento,
          emergency_phone: doc.data().emergency_phone,
          profissao: doc.data().profissao,
          type: doc.data().type,
          password: doc.data().password,
          uid: doc.data().uid,
          typeToCheck: doc.data().typeToCheck,
        });
      }
    });
  };

  const editEmployee = async () => {
    const {
      name,
      phone,
      bi,
      email,
      date_nascimento,
      emergency_phone,
      profissao,
      type,
      password,
      uid,
      typeToCheck,
    } = employee;

    if (!name || !phone || !email) {
      toast.warning("Por favor, preencha as informações pessoais!", {
        hideProgressBar: true,
      });
    } else if (!type) {
      toast.warning("Por favor, selecione o tipo!", {
        hideProgressBar: true,
      });
    } else {

      setLoading(true);
      const query = new URLSearchParams(props.location.search);
      let id = query.get("id");

      const functions = firebase.functions();
      // functions.useEmulator("localhost", 5001)
      const updateEmail = functions.httpsCallable('updateUserEmail');
      updateEmail({ uid: uid, userEmail: email }).then((result) => {
        // console.log(result.data);
      }).catch((error) => {
        // console.log(error);
      });

      const updateRef = db.collection("instrutor").doc(id);
      updateRef
        .update({
          name: name.trim(),
          phone: phone,
          bi: bi,
          email: email.trim(),
          date_nascimento: date_nascimento,
          emergency_phone: emergency_phone,
          profissao: profissao.trim(),
          type: type,
          password: password,
          uid: uid,
          typeToCheck: typeToCheck,
        })
        .then((doc) => {
          setEmployee({
            name: "",
            phone: "",
            bi: "",
            email: "",
            date_nascimento: "",
            emergency_phone: "",
            profissao: "",
            type: [],
            password: "",
            uid: "",
            typeToCheck: "",
          });
          setLoading(false);
          toast.success("Funcionário Actualizado com sucesso!", {
            hideProgressBar: true,
          });
          history.goBack();
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Erro ao editar.", { hideProgressBar: true });
        });
    }
  };

  const handleChange = (e) => {
    setEmployee({
      ...employee,
      type: e,
      typeToCheck: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
    // setEmployee({...employee, type: e});
  };
  return (
    <Container>
      <MainContainer>
        <Aside />

        <MainContent>
          <MainWrapper>
            <div className="header">
              <button onClick={backToHome}>
                <FiChevronLeft size={20} color="#1c1c1c" />
              </button>
              <h1>Editar Funcionário</h1>
            </div>

            <Forms>
              <LeftAside>
                <label>Nome</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={employee.name}
                  onChange={(e) =>
                    setEmployee({ ...employee, name: e.target.value })
                  }
                />
                <label>Email</label>
                <Input type="email"
                  size={100}
                  space={5}
                  value={employee.email}
                  onChange={(e) => setEmployee({ ...employee, email: e.target.value })}
                />
                <label>Bilhete de Identidade</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={employee.bi}
                  onChange={(e) =>
                    setEmployee({ ...employee, bi: e.target.value })
                  }
                />

                <label>Especialidade</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={employee.profissao}
                  onChange={(e) =>
                    setEmployee({ ...employee, profissao: e.target.value })
                  }
                />
              </LeftAside>

              <RightAside>
                <label>Telefone</label>
                <Input
                  size={100}
                  space={5}
                  value={employee.phone}
                  onChange={(e) =>
                    setEmployee({ ...employee, phone: e.target.value })
                  }
                />
                <label>Data de Nascimento</label>
                <Input
                  type="date"
                  size={100}
                  space={5}
                  value={employee.date_nascimento}
                  onChange={(e) =>
                    setEmployee({
                      ...employee,
                      date_nascimento: e.target.value,
                    })
                  }
                />
                <label>Contacto de emergência</label>
                <Input
                  size={100}
                  space={5}
                  value={employee.emergency_phone}
                  onChange={(e) =>
                    setEmployee({
                      ...employee,
                      emergency_phone: e.target.value,
                    })
                  }
                />
                {type === true ? (
                  <>
                    <label>Tipo</label>
                    <Select
                      options={[
                        { value: "Administrador", label: "Administrador" },
                        { value: "Instrutor", label: "Instrutor" },
                        { value: "Outro", label: "Outro" },
                      ]}
                      value={employee.type}
                      ref={selectInputRef}
                      isSearchable={false}
                      isMulti
                      placeholder={"Selecione o tipo"}
                      onChange={handleChange}
                    />
                  </>
                ) : null}
              </RightAside>
            </Forms>
            <div
              className="button_container"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                loading={loading}
                onClick={() => editEmployee()}
                style={{ width: "20%" }}
              >
                {loading ? (
                  <FaSpinner color="#FFF" size={14} />
                ) : (
                  <span>Salvar</span>
                )}
              </Button>
            </div>
          </MainWrapper>
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default EditFuncionario;
