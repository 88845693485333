/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  MainContainer,
  MainContent,
  MainWrapper,
  LeftAside,
  RightAside,
  Forms,
} from "../../styles/Edit-Style";

import Aside from "../../components/Aside";
import Input from "../../components/Input";
import Button from "../../components/Button";

import { db } from "../../services/firebase";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { FiChevronLeft } from "react-icons/fi";

function EditAluno(props) {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    bi: "",
    peso: "",
    date_nascimento: "",
    emergency_phone: "",
    profissao: "",
    photoURL: "",
  });

  const query = new URLSearchParams(props.location.search);
  let id = query.get("id");

  const getUser = async () => {
    const ref = await db.collection("users").doc(id);

    ref.get().then((doc) => {
      if (doc.exists) {
        console.log(doc.data());
        setUser({
          name: doc.data().name,
          phone: doc.data().phone,
          email: doc.data().email,
          bi: doc.data().bi,
          peso: doc.data().peso,
          date_nascimento: doc.data().date_nascimento,
          emergency_phone: doc.data().emergency_phone,
          profissao: doc.data().profissao,
          photoURL: doc.data().photoUrl,
        });
      }
    });
  };

  const editUser = async () => {
    try {
      const { name } = user;

      if (!name) {
        toast.warning(
          "Por favor, preencha os campos! (nome, telefone, email, bi)",
          {
            hideProgressBar: true,
          }
        );
      } else {
        setLoading(true);
        const updateRef = await db.collection("users").doc(id);
        updateRef
          .update(user)
          .then((doc) => {
            setUser({
              name: "",
              phone: "",
              email: "",
              bi: "",
              peso: "",
              date_nascimento: "",
              emergency_phone: "",
              profissao: "",
            });
            setLoading(false);
            toast.info("Dados do Aluno actualizado com sucesso!", {
              hideProgressBar: true,
            });
            history.goBack();
          })
          .catch((error) => {
            setLoading(false);
            toast.error("Erro ao Actualizar dados.", { hideProgressBar: true });
          });
      }
    } catch (err) {
      toast.error("Erro ao Actualizar dados.", { hideProgressBar: true });
      setLoading(false);
    }
  };

  const backToHome = () => {
    history.push("/alunos");
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Container>
      <MainContainer>
        <Aside />

        <MainContent>
          <MainWrapper>
            <div className="header">
              <button onClick={backToHome}>
                <FiChevronLeft size={20} color="#1c1c1c" />
              </button>
              <h1>Actualizar dado do Aluno</h1>
            </div>

            <Forms>
              <div
                style={{
                  width: 340,

                  height: 220,
                  marginLeft: 20,
                }}
              >
                <img src={user.photoURL} alt="Photo URL" />
              </div>

              <LeftAside>
                <label>Nome</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={user.name}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                />
                <label>Email</label>
                <Input
                  type="email"
                  size={100}
                  space={5}
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
                <label>Bilhete de Identidade</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={user.bi}
                  onChange={(e) => setUser({ ...user, bi: e.target.value })}
                />

                <label>Peso</label>
                <Input
                  type="number"
                  size={100}
                  space={5}
                  value={user.peso}
                  onChange={(e) => setUser({ ...user, peso: e.target.value })}
                />
              </LeftAside>

              <RightAside>
                <label>Data de Nascimento</label>
                <Input
                  type="date"
                  size={100}
                  space={5}
                  value={user.date_nascimento}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      date_nascimento: e.target.value,
                    })
                  }
                />
                <label>Contacto de emergência</label>
                <Input
                  size={100}
                  space={5}
                  value={user.emergency_phone}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      emergency_phone: e.target.value,
                    })
                  }
                />

                <label>Profissão</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={user.profissao}
                  onChange={(e) =>
                    setUser({ ...user, profissao: e.target.value })
                  }
                />
              </RightAside>
            </Forms>

            <div
              className="button_container"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                onClick={() => editUser()}
                loading={loading}
                style={{ width: "20%" }}
              >
                {loading ? (
                  <FaSpinner color="#FFF" size={14} />
                ) : (
                  <span>salvar Alterações</span>
                )}
              </Button>
            </div>
          </MainWrapper>
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default EditAluno;
