/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Container,
  MainContainer,
  MainContent,
  SearchContainer,
} from "../../styles/List-Styles";

import { toast } from "react-toastify";

import { db, firebase } from "../../services/firebase";
import { FiSearch, FiEdit, FiTrash } from "react-icons/fi";

import { TableContainer } from "../../styles/TableStyles";
import Modals from "../../components/Modal";

import Button from "../../components/Button";

import { useHistory } from "react-router-dom";

function ListFuncionario() {
  let history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useState({
    selectedIndex: "",
    allemployee: [],
    search: "",
    user: {},
    uid: "",
  });

  const getAllEmployees = async () => {
    const snapshot = await db.collection("instrutor").get();

    let employee = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      employee.push({ id, ...data });
    });

    setState({ ...state, allemployee: employee });
  };

  const searchFunction = () => {
    if (!state.search) {
      getAllEmployees();
    } else {
      let filtered = state.allemployee.filter(
        (obj) =>
          (obj &&
            obj.name.toLowerCase().indexOf(state.search.toLowerCase()) >= 0) ||
          obj.phone.toLowerCase().indexOf(state.search.toLowerCase()) >= 0 ||
          obj.email.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
      );
      setState({ ...state, allemployee: filtered });
    }
  };

  useEffect(() => {
    searchFunction();
  }, [state.search]);

  function EditUSer(id) {
    history.push({
      pathname: "/editar-funcionario",
      search: `?id=${id}`,
    });
  }

  const deleteUser = async () => {
    const functions = firebase.functions();
    // functions.useEmulator("localhost", 5001);
    const delUser = functions.httpsCallable("deleteUserByUid");
    delUser({ uid: state.uid })
      .then((result) => {
        // console.log(result.data);
      })
      .catch((error) => {
        // console.log(error);
      });

    try {
      await db.collection("instrutor").doc(state.selectedIndex).delete();
      toast.success("Funcionário eliminado com sucesso!", {
        hideProgressBar: true,
      });
      getAllEmployees();
      setOpenModal(false);
    } catch (err) {
      toast.error("Ocorreu um erro ao eliminar.");
    }
  };

  return (
    <Container>
      <MainContainer>
        <Modals
          open={openModal}
          setOpenModal2={setOpenModal}
          backgroundColor={"#fff"}
          width={30}
          height={35}
          headerText={"Eliminar"}
          onClose={() => setOpenModal(false)}
          showButons={true}
          onDelete={() => deleteUser()}
          children={<h4>Tem certeza que deseja eliminar?</h4>}
        />
        <MainContent>
          <div className="headers">
            <h2>Todos Funcionários</h2>

            <a href="/registar-funcionario">
              <Button>Registar funcionário</Button>
            </a>
          </div>

          <div className="search_container">
            <SearchContainer>
              <FiSearch size={15} color="#ccc" />
              <input
                placeholder="Pesquisar funcionário..."
                type="text"
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </SearchContainer>
          </div>

          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <TableContainer>
              <table className="no-efectf">
                <thead>
                  <tr className="table-header">
                    <th className="col" style={{ paddingLeft: 10 }}>
                      Nome Completo
                    </th>
                    <th className="col">Telefone</th>
                    <th className="col">Email</th>
                    <th className="col option">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {state.allemployee.map((employee, index) => (
                    <tr>
                      <td
                        className="col"
                        style={{ paddingLeft: 10 }}
                        data-label="Nome:"
                      >
                        {employee.name}
                      </td>
                      <td className="col" data-label="email:">
                        {employee.phone}
                      </td>
                      <td className="col" data-label="estado:">
                        {employee.email}
                      </td>

                      <td className="col icon_table">
                        <FiEdit
                          className="icons"
                          title="Editar"
                          onClick={() => EditUSer(employee.id)}
                        />
                        <FiTrash
                          className="icons"
                          title="Eliminar"
                          onClick={() => {
                            setState({
                              ...state,
                              selectedIndex: employee.id,
                              uid: employee.uid,
                            });
                            setOpenModal(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default ListFuncionario;
