/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { MainContainer, MainContent } from "../../../styles/List-Styles";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { TabsWrapper } from "./../../../styles/TabsWrapper";

import Confirmados from "./dayOftheWeek";
import Pendentes from "./dayOftheWeekPendente";

function DayOfTheweeks() {
  return (
    <div style={{ flex: 1 }}>
      <MainContainer>
        <MainContent>
          <TabsWrapper>
            <Tabs>
              <TabList>
                <Tab>Agendados</Tab>
                <Tab>Lista de Espera</Tab>
              </TabList>

              <TabPanel>
                <Confirmados />
              </TabPanel>

              <TabPanel>
                <Pendentes />
              </TabPanel>
            </Tabs>
          </TabsWrapper>
        </MainContent>
        {/* <TabBar /> */}
      </MainContainer>
    </div>
  );
}

export default DayOfTheweeks;
