import React from "react";

import logoImg from "../../assets/logo.svg";
import { Container } from "./styles";

import { FiBook } from "react-icons/fi";

function Header() {
  return (
    <>
      <Container>
        <a href="/home">
          <img src={logoImg} alt="Reshape" />
        </a>

        <a href="/listar-aula">
          <button>
            <FiBook size={20} color="#555" />
          </button>

          <span>Listar Aulas</span>
        </a>
      </Container>
    </>
  );
}

export default Header;
