import React from "react";
import { FiX } from "react-icons/fi";
import Button from "../Button";

import { Container, ModalContent } from "./styles";

function Modals({
  children,
  open,
  headerText,
  showButons = true,
  onDelete,
  ...props
}) {
  return (
    <ModalContent open={open}>
      <Container {...props}>
        <div className="header_modal">
          <h2>{headerText}</h2>
          <FiX id="button" size={20} color="#555" onClick={props.onClose} />
        </div>
        {children}
        {showButons ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Button
              onClick={() => onDelete()}
              style={{
                backgroundColor: "#FF4C4C",
                color: "#fff",
                border: 0,
                width: "150px",
              }}
            >
              {" "}
              Sim
            </Button>
            <Button
              onClick={props.onClose}
              style={{
                backgroundColor: "#04d361",
                color: "#fff",
                border: 0,
                width: "150px",
              }}
            >
              Não
            </Button>
          </div>
        ) : null}
      </Container>
    </ModalContent>
  );
}

export default Modals;
