/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Container,
  MainContainer,
  MainContent,
  SearchContainer,
} from "../../styles/List-Styles";

import { toast } from "react-toastify";

import { db } from "../../services/firebase";
import { FiSearch, FiEdit, FiTrash, FiEye } from "react-icons/fi";

import { TableContainer } from "../../styles/TableStyles";
import Modals from "../../components/Modal";
import Button from "../../components/Button";

import { useHistory } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

function ListAula() {
  let history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [state, setState] = useState({
    allClasses: [],
    selectedIndex: "",
    search: "",
    days: [],
  });
  const [weekend, setWeekend] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [loading, setLoading] = useState(null);
  const [docId, setDociID] = useState("");

  useEffect(() => {
    searchFunction();
  }, [state.search]);

  function EditAula(id) {
    history.push({
      pathname: "/editar-aula",
      search: `?id=${id}`,
    });
  }

  const getAllCLasses = async () => {
    const snapshot = await db.collection("aulas").get();

    let aula = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      aula.push({ id, ...data });
    });

    /* for (let index = 0; index < aula.length; index++) {
      const element = aula[index];
      let arr = Object.values(element.days);
      for (let index = 0; index < arr.length; index++) {
        const el = arr[index];
        for (let index = 0; index < el.length; index++) {
          const e = el[index];
          console.log(e.begin, e.end);
        }
      }
    } */
    setState({ ...state, allClasses: aula });
  };

  const searchFunction = async () => {
    if (!state.search) {
      getAllCLasses();
    } else {
      let filtered = state.allClasses.filter(
        (obj) =>
          (obj &&
            obj.name.toLowerCase().indexOf(state.search.toLowerCase()) >= 0) ||
          obj.description.toLowerCase().indexOf(state.search.toLowerCase()) >=
            0 ||
          obj.daysWithoutHour.find(
            (obj) =>
              obj && obj.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
          )
      );

      setState({ ...state, allClasses: filtered });
    }
  };

  const onDelete = async () => {
    db.collection("aulas")
      .doc(state.selectedIndex)
      .delete()
      .then(() => {
        toast.success("Aula eliminada com sucesso!", { hideProgressBar: true });
        searchFunction();
        setOpenModal(false);
      })
      .catch((error) => {
        toast.error("Erro ao eliminar ", { hideProgressBar: true });
      });
  };

  const clearAll = () => {
    setOpenModal2(false);
    setWeekend({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
  };
  const getExceptions = (id) => {
    const ref = db.collection("aulas").doc(id);

    ref.get().then((doc) => {
      if (doc.exists) {
        setWeekend({
          monday:
            doc.data() &&
            doc.data().excepcoes &&
            doc.data().excepcoes.includes("Segunda"),
          tuesday:
            doc.data() &&
            doc.data().excepcoes &&
            doc.data().excepcoes.includes("Terça"),
          wednesday:
            doc.data() &&
            doc.data().excepcoes &&
            doc.data().excepcoes.includes("Quarta"),
          thursday:
            doc.data() &&
            doc.data().excepcoes &&
            doc.data().excepcoes.includes("Quinta"),
          friday:
            doc.data() &&
            doc.data().excepcoes &&
            doc.data().excepcoes.includes("Sexta"),
          saturday:
            doc.data() &&
            doc.data().excepcoes &&
            doc.data().excepcoes.includes("Sábado"),
          sunday:
            doc.data() &&
            doc.data().excepcoes &&
            doc.data().excepcoes.includes("Domingo"),
        });
      }
    });
    getAllCLasses();
  };
  const save = (id) => {
    console.log(docId);
    try {
      setLoading(true);
      const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
        weekend;

      let days_of_week = [];

      if (monday == true) {
        days_of_week.push("Segunda");
      }
      if (tuesday == true) {
        days_of_week.push("Terça");
      }
      if (wednesday == true) {
        days_of_week.push("Quarta");
      }
      if (thursday == true) {
        days_of_week.push("Quinta");
      }
      if (friday == true) {
        days_of_week.push("Sexta");
      }
      if (saturday == true) {
        days_of_week.push("Sábado");
      }
      if (sunday == true) {
        days_of_week.push("Domingo");
      }

      const updateRef = db.collection("aulas").doc(docId);
      updateRef
        .update({ excepcoes: days_of_week })
        .then((doc) => {
          setWeekend({
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
          });
          getExceptions();
          toast.success("Excepções adicionadas!", {
            hideProgressBar: true,
          });
          setOpenModal2(false);
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Erro ao adicionar excepções.", {
            hideProgressBar: true,
          });

          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Modals
        open={openModal2}
        setOpenModal2={setOpenModal2}
        backgroundColor={"#fff"}
        width={45}
        height={50}
        headerText={"Dias disponíveis "}
        onClose={() => clearAll()}
        showButons={false}
        children={
          <>
            <span style={{ marginLeft: 20, marginBottom: 30 }}>
              Selecione os dias em que a aula estará disponível
            </span>

            <div style={{ marginBottom: 15 }}>
              <input
                type="checkbox"
                id="check7"
                checked={weekend.sunday}
                onChange={() =>
                  setWeekend({ ...weekend, sunday: !weekend.sunday })
                }
              />
              <label for="check7"> {"Domingo"}</label>
            </div>
            <div style={{ marginBottom: 15 }}>
              <input
                type="checkbox"
                id="check1"
                checked={weekend.monday}
                onChange={() =>
                  setWeekend({ ...weekend, monday: !weekend.monday })
                }
              />
              <label for="check1"> {"Segunda"}</label>
            </div>
            <div style={{ marginBottom: 15 }}>
              <input
                type="checkbox"
                id="check2"
                checked={weekend.tuesday}
                onChange={() =>
                  setWeekend({ ...weekend, tuesday: !weekend.tuesday })
                }
              />
              <label for="check2"> {"Terça"}</label>
            </div>
            <div style={{ marginBottom: 15 }}>
              <input
                type="checkbox"
                id="check3"
                checked={weekend.wednesday}
                onChange={() =>
                  setWeekend({ ...weekend, wednesday: !weekend.wednesday })
                }
              />
              <label for="check3"> {"Quarta"}</label>
            </div>
            <div style={{ marginBottom: 15 }}>
              <input
                type="checkbox"
                id="check4"
                checked={weekend.thursday}
                onChange={() =>
                  setWeekend({ ...weekend, thursday: !weekend.thursday })
                }
              />
              <label for="check4"> {"Quinta"}</label>
            </div>
            <div style={{ marginBottom: 15 }}>
              <input
                type="checkbox"
                id="check5"
                checked={weekend.friday}
                onChange={() =>
                  setWeekend({ ...weekend, friday: !weekend.friday })
                }
              />
              <label for="check5"> {"Sexta"}</label>
            </div>
            <div style={{ marginBottom: 15 }}>
              <input
                type="checkbox"
                id="check6"
                checked={weekend.saturday}
                onChange={() =>
                  setWeekend({ ...weekend, saturday: !weekend.saturday })
                }
              />
              <label for="check6"> {"Sábado"}</label>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{
                    height: 40,
                    backgroundColor: "#00BF6F",
                    color: "#fff",
                    width: "30%",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  type="button"
                  loading={loading}
                  onClick={() => save()}
                >
                  {loading ? (
                    <FaSpinner color="#FFF" size={14} />
                  ) : (
                    <span> Salvar</span>
                  )}
                </Button>
              </div>
            </div>
          </>
        }
      />
      <Modals
        open={openModal}
        setOpenModal2={setOpenModal}
        backgroundColor={"#fff"}
        width={30}
        height={35}
        headerText={"Eliminar"}
        onClose={() => setOpenModal(false)}
        showButons={true}
        onDelete={() => onDelete()}
        children={<h4>Tem certeza que deseja eliminar?</h4>}
      />
      <MainContainer>
        <MainContent>
          <div className="headers">
            <h2>Todas Aulas</h2>
          </div>

          <div className="search_container">
            <SearchContainer>
              <FiSearch size={15} color="#ccc" />
              <input
                placeholder="Pesquisar aulas..."
                type="text"
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </SearchContainer>
          </div>

          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <TableContainer>
              <table className="no-efectf">
                <thead>
                  <tr className="table-header">
                    <th className="col" style={{ paddingLeft: 10 }}>
                      Nome
                    </th>
                    <th className="col">Descrição</th>
                    {/* <th className="col">Vagas disponíveis</th> */}
                    <th className="col">Dias</th>
                    <th className="col option">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {state.allClasses.map((classes, index) => {
                    // let a = Object.keys(classes.days);
                    return (
                      <>
                        <tr key={classes.id}>
                          <td
                            className="col"
                            style={{ paddingLeft: 10 }}
                            data-label="Nome:"
                          >
                            {classes.name}
                          </td>
                          <td className="col" data-label="description:">
                            {classes.description}
                          </td>

                          <td className="col" data-label="vagas-disp:">
                            {classes.daysWithoutHour
                              ? classes.daysWithoutHour.join(", ")
                              : null}
                          </td>

                          {/* <td className="col" data-label="instrutor:">
                        {classes.instrutor}
                      </td> */}
                          <td className="col icon_table">
                            <FiEdit
                              size={16}
                              className="icons"
                              title="Editar"
                              onClick={() => EditAula(classes.id)}
                            />

                            <FiEye
                              size={16}
                              className="icons"
                              title="Gerir"
                              onClick={() => {
                                setDociID(classes.id);
                                // setState({
                                //   ...state,
                                //   selectedIndex: classes.id,
                                // });
                                getExceptions(classes.id);
                                setOpenModal2(true);
                              }}
                            />
                            <FiTrash
                              size={16}
                              onClick={() => {
                                setState({
                                  ...state,
                                  selectedIndex: classes.id,
                                });
                                setOpenModal(true);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default ListAula;
