import React from "react";

import Aside from "../../components/Aside";
// import TabBar from "../../../components/TabBar";

import {
  Container,
  MainContainer,
  MainContent,
} from "../../styles/List-Styles";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { TabsWrapper } from "./../../styles/TabsWrapper";

import List from "./List-Alunos";
import Form from "./Formulario";
import Header from "../../components/Header";

function Alunos() {
  return (
    <>
      <Header />
      <Container>
        <MainContainer>
          <Aside />

          <MainContent>
            <TabsWrapper>
              <Tabs>
                <TabList>
                  <Tab>Todos Alunos</Tab>
                  <Tab className="tab_mobile">Formulário</Tab>
                </TabList>

                <TabPanel>
                  {" "}
                  <List />{" "}
                </TabPanel>

                <TabPanel>
                  <Form />
                </TabPanel>
              </Tabs>
            </TabsWrapper>
          </MainContent>
          {/* <TabBar /> */}
        </MainContainer>
      </Container>
    </>
  );
}

export default Alunos;
