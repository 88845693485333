import styled, { keyframes, css } from "styled-components";
import colors from "../../styles/colors";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button.attrs((props) => ({
  type: "submit",
  disabled: props.loading,
}))`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  border-color: transparent;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: ${colors.green};
  color: #fff;
  outline: none;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  font-size: 15px;
  text-transform: uppercase;
  &:hover {
    opacity: 0.8;
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
  ${(props) =>
    props.loading &&
    css`
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    `}
`;
