// eslint-disable-next-line import/no-anonymous-default-export
export default {
  text: "#000014",
  white: "#fff",
  borderColor: "#e6e8eb",
  background: "#f7f8fa",
  green: "#04d361",
  red: "#ec1414",
  tag: "#323235",
  gray: "#7f7f8b",
  gray_200: "#afb2b1",
  gray_500: "#808080",
  gray_800: "#494d4b",
};
