import styled from "styled-components";
import colors from "../styles/colors";
export const Container = styled.div`
  flex: 1;

  .photos {
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 100px;
      height: 100px;
      display: none;
    }
  }

  .formsResh {
    @media (min-width: 768px) and (max-width: 1024px) {
      display: block;
      width: 100% !important;
    }
  }

  .descriptions {
    @media (min-width: 768px) and (max-width: 1024px) {
      display: block;
      margin-top: 0.2rem !important;
    }
  }

  .showPhoto {
    display: none;

    @media (min-width: 768px) and (max-width: 1024px) {
      display: block;
      width: 100%;
      height: 180px;
      margin-left: 30px;
    }
  }
`;

export const MainContainer = styled.main`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

export const MainContent = styled.div`
  width: calc(100% - 230px);
  background-color: transparent;
  border-top: 1px solid ${colors.borderColor};
  padding: 0 80px;

  @media (min-width: 1025px) and (max-width: 1280px) {
    padding: 0 30px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 30px;
  }
`;

export const MainWrapper = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.borderColor};

  margin-top: 30px;
  height: 80vh;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  @media (min-width: 1025px) and (max-width: 1280px) {
    height: 90vh;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 90vh;
  }

  > .header {
    border-bottom: 1px solid ${colors.borderColor};
    padding: 10px 50px;
    height: 60px;
    display: flex;
    align-items: center;

    > h1 {
      font-size: 22px;
    }

    > button {
      background-color: transparent;
      border: 0;
      margin-right: 20px;
      margin-top: 5px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  > .button_container {
    margin-top: -60px;
    padding-right: 60px;

    @media (min-width: 1025px) and (max-width: 1280px) {
      margin-top: -10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-top: -10px;
    }
    > button {
      width: 32%;
      height: 48px;
      background: #00aaff;
    }
  }
`;

export const Forms = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  /* background-color: red; */
`;

export const LeftAside = styled.aside`
  width: 50%;
  height: 55vh;
  padding: 0 55px;

  > label {
    display: block;
    margin: 20px 0;
  }
`;

export const RightAside = styled.aside`
  padding: 0 55px;
  height: 55vh;
  width: 50%;

  > label {
    display: block;
    margin: 20px 0;
  }
`;

// For Add Aulas´

export const FormsAula = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  margin-top: 30px;
`;

export const PhotoContainers = styled.div`
  width: 45%;
  height: 35vh;
  margin-top: 25px;

  > label#thumbnail {
    margin-bottom: 20px;
    border: 1px dashed #ddd;
    /* background-size: cover; */
    cursor: pointer;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;

    @media (min-width: 1025px) and (max-width: 1280px) {
      width: 10rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 10rem;
    }
  }

  > label#thumbnail input {
    display: none;
  }

  > label#thumbnail.has-thumbnail {
    border: 0;
  }

  > label#thumbnail.has-thumbnail img {
    display: none;
  }
`;

export const FormsContent = styled.div`
  width: 70%;

  display: flex;
  justify-content: space-between;

  > .rights {
    width: 50%;

    > label {
      display: block;
      margin: 25px 0;
    }
  }

  > .lefts {
    width: 45%;

    > label {
      display: block;
      margin: 25px 0;
    }
  }
`;
