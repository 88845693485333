import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  color: #1c1c1c;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
  height: 410px;

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 75%;
  }

  @media (width: 3200px) {
    width: 30%;
    height: 430px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 540px) and (max-width: 720px) {
    width: 80%;
    background-color: transparent;
  }
`;

export const LeftContent = styled.aside`
  width: 50%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }

  @media (min-width: 540px) and (max-width: 720px) {
    display: none;
  }

  > img {
    width: 250px;
  }

  > span {
    margin: 30px 0;
    font-size: 1.2rem;
  }
`;

export const RightContent = styled.aside`
  width: 50%;
  background-color: #fafafa;
  padding: 20px;

  border-radius: 4px;

  > h1 {
    display: none;

    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
      font-size: 2rem;
      text-align: center;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    background-color: transparent;
  }

  @media (min-width: 540px) and (max-width: 720px) {
    width: 100%;
    background-color: transparent;
  }

  form {
    margin-top: 20px;
    > label {
      display: block;
      margin: 20px 0;
      font-weight: 400;
    }

    > input {
      width: 100%;
      height: 50px;
      border: 1px solid #ddd;
      padding: 0 15px;
      border-radius: 4px;
      font-size: 15.5px;
      color: #1c1c1c;

      &::placeholder {
        color: #939393;
        font-size: 14.5px;
      }
    }

    > a {
      color: #4140ea;
      display: block;
      text-align: center;
      font-size: 12px;
      transition: color 0.2;
      margin-top: 30px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
