/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// import Header from "../../components/Header";
// import Aside from "../../components/Aside";

import {
  Container,
  MainContainer,
  MainContent,
  SearchContainer,
} from "../../styles/List-Styles";

import { toast } from "react-toastify";
import "jspdf-autotable";

import { db, firebase } from "../../services/firebase";
import { FiSearch, FiEdit, FiTrash } from "react-icons/fi";

import { RiFileList3Line } from "react-icons/ri";

import { TableContainer } from "../../styles/TableStyles";
import Modals from "../../components/Modal";

import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import jsPDF from "jspdf";
import logo_B64 from "../../assets/logo_B64";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FaSpinner } from "react-icons/fa";
import { BiBlock } from "react-icons/bi";

// import TabBar from "../../components/TabBar";

function ListAluno() {
  let history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [extractPdf, setExtractPdf] = useState(false);

  const [openModalBlock, setOpenModalblock] = useState(false);

  const [state, setState] = useState({
    selectedIndex: "",
    allUsers: [],
    search: "",
    user: {},
    uid: "",
    userLogedIn: "",
    idUser: "",
    allPaymentsByUser: [],
  });

  useEffect(() => {
    searchFunction();
  }, [state.search]);

  function EditUSer(id) {
    history.push({
      pathname: "/editar-aluno",
      search: `?id=${id}`,
    });
  }

  const getInstrutor = async () => {
    const id = localStorage.getItem("Reshape:user");

    const snapshot = await db
      .collection("instrutor")
      .where("uid", "==", id)

      .get();

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();
      setState({ ...state, userLogedIn: data.name });
    });
  };

  const getAllUsers = async () => {
    const snapshot = await db
      .collection("users")
      .orderBy("estado", "asc")
      .get();

    let users = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      users.push({ id, ...data });
    });

    setState({ ...state, allUsers: users });
  };

  const getAllPayments = async (id) => {
    setLoading(true);
    const snapshot = await db
      .collection("pagamentos")
      .where("idUser", "==", id)
      .get();

    let payments = [];

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      payments.push({ id, ...data });
    });
    setState({ ...state, allPaymentsByUser: payments });
    setLoading(false);
  };

  const searchFunction = async () => {
    if (!state.search) {
      getAllUsers();
    } else {
      let filtered = state.allUsers.filter(
        (obj) =>
          (obj &&
            obj.name.toLowerCase().indexOf(state.search.toLowerCase()) >= 0) ||
          obj.phone.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
      );
      setState({ ...state, allUsers: filtered });
    }
  };

  async function deleteUser() {
    const functions = firebase.functions();
    const deleteUser = functions.httpsCallable("deleteUserByUid");
    deleteUser({ uid: state.uid })
      .then((result) => {
        // console.log(result.data);
      })
      .catch((error) => {
        // console.log(error);
      });
    try {
      await db.collection("users").doc(state.selectedIndex).delete();
      getAllUsers();
      toast.error("Aluno eliminado com sucesso!");
      getAllUsers();
      setOpenModal2(false);
    } catch (err) {
      toast.error("Ocorreu um erro", { hideProgressBar: true });
    }
  }

  const unBlockUser = async () => {
    await db
      .collection("users")
      .doc(state.selectedIndex)
      .update({
        estado: true,
      })
      .then(() => {
        setOpenModal(false);
        toast.success("Utilizador desbloqueado com sucesso!", {
          hideProgressBar: true,
        });
        getAllUsers();
      })
      .catch((error) => {
        toast.error("Erro ao desbloquear! ", { hideProgressBar: true });
      });
  };

  const getCurrentDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    const HH = today.getHours();
    const MM = today.getMinutes();

    return (today = dd + "/" + mm + "/" + yyyy + " - Hora:" + HH + ":" + MM);
  };

  const blockUser = async () => {
    await db
      .collection("users")
      .doc(state.idUser)
      .set(
        {
          estado: false,
        },
        { merge: true }
      )
      .then(() => {
        setOpenModalblock(false);
        toast.success("Utilizador bloqueado com sucesso!", {
          hideProgressBar: true,
        });
        getAllUsers();
      })
      .catch((error) => {
        toast.error("Erro ao bloquear! ");
      });
  };

  const seeBills = () => {
    getInstrutor();
    setLoading(true);
    const { allPaymentsByUser } = state;

    const doc = new jsPDF("l", "pt", "A4");

    doc.setFontSize(12);

    doc.addImage(logo_B64, "JPEG", 40, 20, 52, 48);

    const head = [
      ["Aluno", "Mês", "Valor", "Multa", "Total", "Data", "estado"],
    ];

    const body = allPaymentsByUser.map((d) => [
      d.name,
      d.month,
      d.value,
      d.multa,
      d.total,
      d.dateOf,
      d.status === true ? "Efectuado" : "Pendente",
    ]);

    const content = {
      headStyles: { fillColor: [1, 61, 47] },
      margin: { top: 10 },
      startY: 80,
      head,
      body,
    };
    setLoading(false);

    doc.text("RESHAPE - Extrato de contas", 100, 65);
    doc.text("Extraído por:", 600, 65);
    doc.text(
      state.userLogedIn === "Admin"
        ? state.userLogedIn
        : state.userLogedIn.split(" ").slice(0, -1).join(" "),
      680,
      65
    );
    doc.text("Data actual:", 500, 550);
    doc.text(getCurrentDate(), 575, 550);
    doc.autoTable(content);
    doc.output("dataurlnewwindow");
    // doc.save("Extratos de contas.pdf")
  };

  return (
    <Container>
      <MainContainer>
        <Modals
          open={openModalBlock}
          setOpenModal2={setOpenModalblock}
          backgroundColor={"#fff"}
          width={30}
          height={35}
          headerText={"Bloquear"}
          onClose={() => setOpenModalblock(false)}
          showButons={true}
          onDelete={() => blockUser()}
          children={<h4>Tem certeza que deseja bloquear?</h4>}
        />

        <Modals
          open={extractPdf}
          extractPdf={setExtractPdf}
          backgroundColor={"#fff"}
          width={30}
          height={35}
          headerText={"Extrato de Contas"}
          onClose={() => setExtractPdf(false)}
          showButons={false}
          children={
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h4>Clique em uma das opções </h4>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    loading={loading}
                    onClick={() => {
                      seeBills();
                      setExtractPdf(false);
                    }}
                    style={{
                      backgroundColor: "#04d361",
                      color: "#fff",
                      border: 0,
                      width: "150px",
                      marginRight: "15px",
                    }}
                  >
                    {loading ? (
                      <FaSpinner color="#FFF" size={14} />
                    ) : (
                      <span>Pdf </span>
                    )}
                  </Button>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn_extract"
                    table="table-to-xls"
                    filename="extrato"
                    sheet="Sheet"
                    buttonText="Excel"
                  />
                </div>
              </div>
            </>
          }
        />
        <Modals
          open={openModal2}
          setOpenModal2={setOpenModal2}
          backgroundColor={"#fff"}
          width={30}
          height={35}
          headerText={"Eliminar"}
          onClose={() => setOpenModal2(false)}
          showButons={true}
          onDelete={() => deleteUser()}
          children={<h4>Tem certeza que deseja eliminar?</h4>}
        />
        <Modals
          open={openModal}
          setOpenModal2={setOpenModal}
          backgroundColor={"#fff"}
          width={30}
          height={35}
          headerText={"Desbloquear"}
          onClose={() => setOpenModal(false)}
          showButons={true}
          onDelete={() => unBlockUser()}
          children={<h4>Tem certeza que deseja desbloquear?</h4>}
        />
        <MainContent>
          <div className="headers">
            <h2>Todos Alunos</h2>
          </div>

          <div className="search_container">
            <SearchContainer>
              <FiSearch size={15} color="#ccc" />
              <input
                placeholder="Pesquisar alunos..."
                type="text"
                onChange={(e) => setState({ ...state, search: e.target.value })}
              />
            </SearchContainer>
          </div>

          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <TableContainer>
              <table className="no-efectf">
                <thead>
                  <tr className="table-header">
                    <th className="col" style={{ paddingLeft: 10 }}>
                      Nome Completo
                    </th>
                    <th className="col">Telefone</th>
                    <th className="col">Estado</th>
                    <th className="col option">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {state.allUsers.map((user, index) => (
                    <tr key={user.id}>
                      <td
                        className="col"
                        style={{ paddingLeft: 10 }}
                        data-label="Nome:"
                      >
                        {user.name}
                      </td>
                      <td className="col" data-label="email:">
                        {user.phone}
                      </td>
                      <td className="col" data-label="estado:">
                        {user.estado === false ? "Pendente" : "Inscrito"}
                      </td>

                      <td className="col icon_table">
                        {user.estado === false ? (
                          <button
                            style={{
                              backgroundColor: "red",
                              border: 0,
                              color: "#f6f6f6",
                              padding: 5,
                              borderRadius: 3,
                            }}
                            onClick={() => {
                              setOpenModal(true);
                              setState({ ...state, selectedIndex: user.id });
                            }}
                          >
                            Desbloqueiar
                          </button>
                        ) : null}
                        <FiEdit
                          title="Editar"
                          className="icons"
                          onClick={() => EditUSer(user.id)}
                        />

                        {user.estado === true ? (
                          <BiBlock
                            onClick={() => {
                              setOpenModalblock(true);
                              setState({ ...state, idUser: user.id });
                            }}
                          />
                        ) : null}
                        <FiTrash
                          className="icons"
                          title="Eliminar"
                          onClick={() => {
                            setState({
                              ...state,
                              selectedIndex: user.id,
                              uid: user.uid,
                            });
                            setOpenModal2(true);
                          }}
                        />
                        <RiFileList3Line
                          className="icons"
                          title="Extrato de conta"
                          onClick={() => {
                            setExtractPdf(true);
                            getAllPayments(user.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </div>
          <div style={{ paddingLeft: 20, paddingRight: 20, display: "none" }}>
            <TableContainer>
              <table id="table-to-xls" className="no-efectf">
                <thead>
                  <tr className="">
                    {/* <th className="" style={{ paddingLeft: 10 }}>
                      <img src={logo} />
                    </th> */}
                    <th className="" style={{ paddingLeft: 20 }}>
                      {"Extraído por: " + state.userLogedIn}
                    </th>
                  </tr>
                  <tr className="table-header">
                    <th className="col" style={{ paddingLeft: 10 }}>
                      Nome Completo
                    </th>
                    <th className="col">Mês</th>
                    <th className="col">Valor</th>
                    <th className="col">Multa</th>
                    <th className="col">Total</th>
                    <th className="col">Data</th>
                    <th className="col">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {state.allPaymentsByUser.map((user, index) => (
                    <tr key={user.id}>
                      <td
                        className="col"
                        style={{ paddingLeft: 10 }}
                        data-label="Nome:"
                      >
                        {user.name}
                      </td>
                      <td className="col" data-label="month:">
                        {user.month}
                      </td>
                      <td className="col" data-label="value:">
                        {user.value}
                      </td>
                      <td className="col" data-label="multa:">
                        {user.multa}
                      </td>
                      <td className="col" data-label="total:">
                        {user.total}
                      </td>
                      <td className="col" data-label="date:">
                        {user.dateOf}
                      </td>
                      <td className="col" data-label="status:">
                        {user.status === false ? "Efectuado" : "Pendente"}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <h4 style={{ display: "flex", justifyContent: "flex-end" }}>
                    {getCurrentDate()}
                  </h4>
                </tfoot>
              </table>
            </TableContainer>
          </div>
        </MainContent>
        {/* <TabBar /> */}
      </MainContainer>
    </Container>
  );
}

export default ListAluno;
