/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Container,
  MainContainer,
  MainContent,
  MainWrapper,
  FormsContent,
} from "../../styles/Edit-Style";

// import Header from "../../components/Header";
import Aside from "../../components/Aside";
import Input from "../../components/Input";
import Button from "../../components/Button";

import { firebase } from "../../services/firebase";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import Modals from "../../components/Modal";

function Perfil(props) {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instructor, setInstructor] = useState({
    name: "",
    phone: "",
    email: "",
    bi: "",
    date_nascimento: "",
    emergency_phone: "",
    profissao: "",
    type: "",
    password: "",
    uid: "",
  });
  const [state, setstate] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
    id: "",
  });

  useEffect(() => {
    getInstrutor();
  }, []);

  const db = firebase.firestore();

  const getInstrutor = async () => {
    const id = localStorage.getItem("Reshape:user");

    const snapshot = await db
      .collection("instrutor")
      .where("uid", "==", id)
      .get();

    snapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      setInstructor({
        name: data.name,
        phone: data.phone,
        email: data.email,
        bi: data.bi,
        date_nascimento: data.date_nascimento,
        emergency_phone: data.emergency_phone,
        profissao: data.profissao,
        type: { value: data.type, label: data.type },
        password: data.password,
        uid: data.uid,
      });
      setstate({ ...state, id: id });
    });
  };

  const reautenticate = () => {
    const email = localStorage.getItem("Reshape:email");

    const user = firebase.auth().currentUser;
    let credentials = firebase.auth.EmailAuthProvider.credential(
      email,
      instructor.password
    );
    return user.reauthenticateWithCredential(credentials);
  };

  const changePassword = () => {
    try {
      reautenticate(instructor.password).then(() => {
        const user = firebase.auth().currentUser;
        user
          .updatePassword(state.newPassword)
          .then(() => {
            toast.success("Senha actualizada com sucesso!", {
              hideProgressBar: true,
            });
          })
          .catch((error) => {
            toast.error(
              "Erro ao actualizar senha!",
              "Certifica-te de que a senha tem 6 ou mais caracteres.",
              { hideProgressBar: true }
            );
          });
      });
    } catch (error) {
      toast.error("Erro ao actualizar senha!", { hideProgressBar: true });
    }
  };
  const changeEmail = () => {
    try {
      reautenticate(instructor.password).then(() => {
        const user = firebase.auth().currentUser;
        user
          .updateEmail(instructor.email)
          .then(() => {
            toast.success("Email actualizado com sucesso!", {
              hideProgressBar: true,
            });
          })
          .catch((error) => {
            toast.error("Erro ao actualizar email!", { hideProgressBar: true });
          });
      });
    } catch (error) {
      toast.error("Erro ao actualizar email!", { hideProgressBar: true });
    }
  };

  const editInstrutor = async () => {
    const {
      name,
      phone,
      email,
      bi,
      date_nascimento,
      emergency_phone,
      profissao,
      type,
    } = instructor;

    if (!name || !email || !bi) {
      toast.warning("Por favor, preencha as informações pessoais!", {
        hideProgressBar: true,
      });
    } else if (!type) {
      toast.warning("Por favor, selecione o tipo!", {
        hideProgressBar: true,
      });
    } else {
      setLoading(true);
      changeEmail();
      const updateRef = firebase
        .firestore()
        .collection("instrutor")
        .doc(state.id);

      updateRef
        .update({
          name: name,
          phone: phone,
          email: email,
          bi: bi,
          date_nascimento: date_nascimento,
          emergency_phone: emergency_phone,
          profissao: profissao,
          type: type.value,
          uid: instructor.uid,
          password: instructor.password,
        })
        .then((doc) => {
          setLoading(false);
          toast.success("Funcionário Actualizado com sucesso!", {
            hideProgressBar: true,
          });
          setOpenModal(false);
          getInstrutor();
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Erro ao editar.", { hideProgressBar: true });
        });
    }
  };
  const editPassword = () => {
    const {
      name,
      phone,
      email,
      bi,
      date_nascimento,
      emergency_phone,
      profissao,
      type,
    } = instructor;

    if (instructor.password !== state.password) {
      toast.warning("Palavra-passe actual incorreta!", {
        hideProgressBar: true,
      });
    } else if (state.newPassword !== state.confirmPassword) {
      toast.warning("(Novas) Senhas diferentes!", { hideProgressBar: true });
    } else {
      changePassword();
      const updateRef = firebase
        .firestore()
        .collection("instrutor")
        .doc(state.id);
      updateRef
        .update({
          name: name.trim(),
          phone: phone,
          email: email.trim(),
          bi: bi,
          date_nascimento: date_nascimento,
          emergency_phone: emergency_phone,
          profissao: profissao.trim(),
          type: type.value,
          password: state.newPassword,
          uid: instructor.uid,
        })
        .then((doc) => {
          setstate({
            password: "",
            confirmPassword: "",
            newPassword: "",
          });
          setLoading(false);
          setOpenModal2(false);
          getInstrutor();
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Erro ao editar.", { hideProgressBar: true });
        });
    }
  };

  return (
    <Container>
      {/* <Header /> */}

      <MainContainer>
        <Aside />
        <Modals
          open={openModal2}
          setOpenModal2={setOpenModal2}
          backgroundColor={"#fff"}
          width={35}
          height={55}
          headerText={"Editar Palavra-passe"}
          onClose={() => setOpenModal2(false)}
          showButons={false}
          children={
            <>
              <FormsContent style={{ flexDirection: "column" }}>
                <label>Senha actual</label>
                <Input
                  type="password"
                  placeholder="Digite a senha actual"
                  size={100}
                  space={5}
                  value={state.password}
                  onChange={(e) =>
                    setstate({ ...state, password: e.target.value })
                  }
                />
                <label>Nova Senha</label>
                <Input
                  type="password"
                  placeholder="Digite a nova senha"
                  size={100}
                  space={5}
                  value={state.newPassword}
                  onChange={(e) =>
                    setstate({ ...state, newPassword: e.target.value })
                  }
                />
                <label>Confirmar Nova senha</label>
                <Input
                  type="password"
                  placeholder="Digite a nova senha"
                  size={100}
                  space={5}
                  value={state.confirmPassword}
                  onChange={(e) =>
                    setstate({ ...state, confirmPassword: e.target.value })
                  }
                />
              </FormsContent>
              <div
                className="button_container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 5,
                }}
              >
                <Button onClick={editPassword} loading={loading}>
                  {loading ? (
                    <FaSpinner color="#FFF" size={14} />
                  ) : (
                    <span>Salvar Alterações</span>
                  )}
                </Button>
              </div>
            </>
          }
        />
        <Modals
          open={openModal}
          setOpenModal={setOpenModal}
          backgroundColor={"#fff"}
          width={60}
          height={65}
          headerText={"Editar Meus Dados "}
          onClose={() => setOpenModal(false)}
          showButons={false}
          children={
            <>
              <div>
                <FormsContent>
                  <aside className="left_container">
                    <label>Nome</label>
                    <Input
                      type="text"
                      size={100}
                      space={5}
                      height={43}
                      placeholder="Digite o nome"
                      value={instructor.name}
                      onChange={(e) =>
                        setInstructor({
                          ...instructor,
                          name: e.target.value,
                        })
                      }
                    />
                    <label>Email</label>
                    <Input
                      readOnly={true}
                      type="email"
                      size={100}
                      space={5}
                      height={43}
                      placeholder="Digite o email"
                      value={instructor.email}
                      onChange={(e) =>
                        setInstructor({
                          ...instructor,
                          email: e.target.value,
                        })
                      }
                    />
                  </aside>

                  <aside className="right_container">
                    <label>Bilhete de Identidade</label>
                    <Input
                      type="text"
                      size={100}
                      space={5}
                      height={43}
                      placeholder="Digite o nº do BI"
                      value={instructor.bi}
                      onChange={(e) =>
                        setInstructor({
                          ...instructor,
                          bi: e.target.value,
                        })
                      }
                    />
                  </aside>
                </FormsContent>
              </div>

              <div
                className="button_container"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button onClick={editInstrutor} loading={loading}>
                  {loading ? (
                    <FaSpinner color="#FFF" size={14} />
                  ) : (
                    <span>Salvar Alterações</span>
                  )}
                </Button>
              </div>
            </>
          }
        />
        <MainContent>
          <MainWrapper
            style={{
              backgroundColor: "#fff",
              border: 0,
              borderColor: "#ddd",
              borderRadius: 4,
              borderWidth: 1,
            }}
          >
            <div
              className="header"
              style={{
                backgroundColor: "transparent",
                border: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h1 style={{ textAlign: "center" }}>Minha conta</h1>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
               
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: 400,
                }}
              >
                <label>Nome</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={instructor.name}
                  onChange={(e) =>
                    setInstructor({
                      ...instructor,
                      name: e.target.value,
                    })
                  }
                />
                <label>Email</label>
                <Input
                  type="email"
                  size={100}
                  space={5}
                  value={instructor.email}
                  onChange={(e) =>
                    setInstructor({
                      ...instructor,
                      email: e.target.value,
                    })
                  }
                />
                <label>Bilhete de Identidade</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={instructor.bi}
                  onChange={(e) =>
                    setInstructor({
                      ...instructor,
                      bi: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div
              className="button_container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginLeft: "50px",
              }}
            >
              <Button
                loading={loading}
                style={{ width: "10%" }}
                onClick={() => setOpenModal(true)}
              >
                {loading ? (
                  <FaSpinner color="#FFF" size={14} />
                ) : (
                  <span>Actualizar</span>
                )}
              </Button>

              <Button
                style={{ width: "10%", backgroundColor: "#ddd" }}
                className="btnpassword"
                onClick={() => setOpenModal2(true)}
              >
                <span>Alterar senha</span>
              </Button>
            </div>
          </MainWrapper>
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default Perfil;
