/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import Aside from "../../components/Aside";

import {
  Container,
  MainContainer,
  MainContent,
  CardContainer,
  Card,
  MainWrapper,
} from "./styles";

import { TableContainer } from "../../styles/TableStyles";

import { db } from "../../services/firebase";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";

function Home() {
  const [TotalUser, setTotalUSer] = useState(0);
  const [TotalBooking, setTotalBooking] = useState(0);
  const [TotalPayPendant, setTotalPayPendant] = useState(0);
  const [TotalPayDone, setTotalPayDone] = useState(0);

  const [allUsers, setAllUser] = useState([]);
  let history = useHistory();

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  let daysOfWeek = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];

  let todayDate = dd + "-" + mm + "-" + yyyy;

  async function getUSerTotal() {
    const response = await db.collection("users").get();
    let users = [];
    response.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();
      users.push({ id, ...data });
    });

    setTotalUSer(users.length);
    setAllUser(users);
  }

  async function getBookingTotal() {
    try {
      const response = await db
        .collection("booking")
        // .where("dia", "==", todayDate)
        .get();

      let bokkings = [];
      let newArray = [];

      response.forEach((doc) => {
        let id = doc.id;
        let data = doc.data();

        bokkings.push({ id, ...data });

        newArray = bokkings.filter(function (el) {
          return el && el.id.includes(todayDate);
        });
      });

      const allSchedules = [];

      for (const j in newArray) {
        allSchedules.push(newArray[j].agendado.length);
      }

      const sumSchedules = allSchedules.reduce((a, b) => a + b);

      setTotalBooking(sumSchedules);
    } catch (error) {
      console.log(error);
    }
  }

  async function getPayPendants() {
    const response = await db
      .collection("pagamentos")
      .where("status", "==", false)
      .get();

    let pays = [];

    response.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      pays.push({ id, ...data });
    });

    setTotalPayPendant(pays.length);
  }

  async function getPayDone() {
    const response = await db
      .collection("pagamentos")
      .where("status", "==", true)
      .get();

    let pays = [];

    response.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();

      pays.push({ id, ...data });
    });

    setTotalPayDone(pays.length);
  }

  useEffect(() => {
    getUSerTotal();
    getBookingTotal();
    getPayDone();
    getPayPendants();
  }, [allUsers]);

  return (
    <Container>
      <Header />
      <MainContainer>
        <Aside />

        <MainContent>
          <CardContainer>
            <Card onClick={() => history.push("/alunos")}>
              <h1>{TotalUser}</h1>
              <span>Alunos Registados</span>
            </Card>

            <Card onClick={() => history.push("/booking")}>
              <h1>{TotalBooking}</h1>
              <span>Agendamentos</span>
            </Card>

            <Card onClick={() => history.push("/pagamentos-efectuados")}>
              <h1>{TotalPayDone}</h1>
              <span>Pagamentos Efectuados</span>
            </Card>

            <Card onClick={() => history.push("/pagamentos")}>
              <h1>{TotalPayPendant}</h1>
              <span>Pagamentos Pendentes</span>
            </Card>
          </CardContainer>

          <MainWrapper>
            <h1>Últimos alunos Registados</h1>

            <TableContainer>
              <table className="no-efectf">
                <thead>
                  <tr className="table-header">
                    <th className="col" style={{ paddingLeft: 10 }}>
                      Nome Completo
                    </th>
                    <th className="col">Telefone</th>
                    <th className="col">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers.map((user, index) => (
                    <tr>
                      <td
                        className="col"
                        style={{ paddingLeft: 10 }}
                        data-label="Nome:"
                      >
                        {user.name}
                      </td>
                      <td className="col" data-label="email:">
                        {user.phone}
                      </td>
                      <td className="col" data-label="estado:">
                        {user.status === false ? "Pendente" : "Inscrito"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </MainWrapper>
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default Home;
