/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Aside from "../../components/Aside";

import {
  Container,
  MainContainer,
  MainContent,
} from "../../styles/List-Styles";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { TabsWrapper } from "./../../styles/TabsWrapper";

import Confirmado from "./Confirmados";
import Pendentes from "./Pendentes";
import Tomorrow from "./Tomorrow/";
import Header from "../../components/Header";
import DayOfTheweek from "./DayOftheweek";
import Monitoring from "./Monitoring";

function Pedidos() {
  return (
    <>
      <Header />
      <Container>
        <MainContainer>
          <Aside />

          <MainContent>
            <TabsWrapper>
              <Tabs>
                <TabList>
                  <Tab>Alunos Agendados</Tab>
                  <Tab>Alunos na lista de Espera</Tab>
                  <Tab>Próximo Dia</Tab>
                  <Tab>Dia da Semana</Tab>
                  <Tab>Monitoração</Tab>
                </TabList>

                <TabPanel>
                  <Confirmado />
                </TabPanel>

                <TabPanel>
                  <Pendentes />
                </TabPanel>

                <TabPanel>
                  <Tomorrow />
                </TabPanel>

                <TabPanel>
                  <DayOfTheweek />
                </TabPanel>
                <TabPanel>
                  <Monitoring />
                </TabPanel>
              </Tabs>
            </TabsWrapper>
          </MainContent>
        </MainContainer>
      </Container>
    </>
  );
}

export default Pedidos;
