import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import colors from "../styles/colors";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background-color: ${colors.background};
    color: ${colors.tag};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    outline: none;

    ::-webkit-scrollbar{
      width: 5px;
    }

    overflow: hidden;

  }

  ul, ol {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  input {
    outline: none;
  }

  a{
    text-decoration: none;
  }

  .formsProfile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 35rem;

    >div {
      width: 100%;
      margin: 20px 0;
    }
  }

  .btnpassword {
    margin-left: 30px;
    background-color: #fff;
    color: #000;
  }

  textarea { 
    color: #555;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  input[type=date] {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;

  color: #555;
  
  }

  .btnPrints {
    >button {
      background-color: #04d361;
      padding: 10px ;
      border: 1px solid transparent;
      color: #fff;
      border-radius: 4px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .mobileConfirmation {
    @media (min-width: 320px) and (max-width: 480px) {

      width: 300px;
    }
  }
  .tab_mobile {
    display: inline-block;
    opacity: 1;
    @media (min-width: 320px) and (max-width: 480px) {
      opacity: 0;
    }
  }

  .center-photo {
    object-fit: contain;
  }
  
`;
