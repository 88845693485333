/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Aside from "../../components/Aside";

import {
  Container,
  MainContainer,
  MainContent,
} from "../../styles/List-Styles";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { TabsWrapper } from "./../../styles/TabsWrapper";

import Processar from "./Processar";
import Efectuados from "./Efectuados";
import Confirmados from "./Confirmados";
import Header from "../../components/Header";

function Pedidos() {
  return (
    <>
      <Header />
      <Container>
        <MainContainer>
          <Aside />

          <MainContent>
            <TabsWrapper>
              <Tabs>
                <TabList>
                  <Tab>Pagamentos Efectuados</Tab>
                  <Tab>Pagamentos Processados</Tab>
                  <Tab className="tab_mobile">Emissão de Pagamentos</Tab>
                </TabList>

                <TabPanel>
                  <Efectuados />{" "}
                </TabPanel>

                <TabPanel>
                  <Confirmados />{" "}
                </TabPanel>

                <TabPanel>
                  <Processar />
                </TabPanel>
              </Tabs>
            </TabsWrapper>
          </MainContent>
        </MainContainer>
      </Container>
    </>
  );
}

export default Pedidos;
