/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  MainContainer,
  MainContent,
  MainWrapper,
  LeftAside,
  RightAside,
  Forms,
} from "../../styles/Edit-Style";

import Aside from "../../components/Aside";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "react-select";

import { db } from "../../services/firebase";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { FiChevronLeft } from "react-icons/fi";
import { firebase } from "../../services/firebase";

function RegisterFuncionario(props) {
  let history = useHistory();
  const selectInputRef = useRef();

  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState({
    name: "",
    phone: "",
    email: "",
    bi: "",
    peso: "",
    date_nascimento: "",
    emergency_phone: "",
    profissao: "",
    password: "reshape",
    type: [],
    uid: "",
    typeToCheck: "",
  });
  const createEmployee = async () => {
    const {
      name,
      phone,
      email,
      bi,
      date_nascimento,
      emergency_phone,
      profissao,
      type,
      password,
      typeToCheck,
    } = employee;

    if (!name || !phone || !email) {
      toast.warning("Por favor, preencha as informações pessoais!", {
        hideProgressBar: true,
      });
    } else if (!type) {
      toast.warning("Por favor, selecione o tipo!", {
        hideProgressBar: true,
      });
    } else {
      setLoading(true);
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(employee.email, employee.password)
          .then((response) => {
            const uid = response.user.uid;
            db.collection("instrutor").add({
              name: name.trim(),
              phone: phone,
              email: email.trim(),
              bi: bi,
              date_nascimento: date_nascimento,
              emergency_phone: emergency_phone,
              profissao: profissao.trim(),
              password: password,
              uid: uid,
              type: type,
              typeToCheck: typeToCheck,
            });
            setEmployee({
              name: "",
              phone: "",
              email: "",
              bi: "",
              peso: "",
              date_nascimento: "",
              emergency_phone: "",
              profissao: "",
              password: "reshape",
              type: [],
              uid: "",
              typeToCheck: "",
            });
            selectInputRef.current.select.clearValue();
            setLoading(false);
            toast.success("Funcionário salvo com sucesso!", {
              hideProgressBar: true,
            });
          })
          .catch((error) => {
            if (error.code === "auth/email-already-in-use") {
              setLoading(false);
              toast.error("Já existe um usuário com este email!", {
                hideProgressBar: true,
              });
            }

            if (error.code === "auth/weak-password") {
              setLoading(false);
              toast.error("Cria uma senha com 6 ou mais caracteres.", {
                hideProgressBar: true,
              });
            }
          });
      } catch (err) {
        setLoading(false);
        toast.error("Erro ao salvar", { hideProgressBar: true });
      }
    }
  };

  const backToHome = () => {
    history.push("/funcionario");
  };
  const handleChange = (e) => {
    setEmployee({
      ...employee,
      type: e,
      typeToCheck: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
  };

  return (
    <Container>
      <MainContainer>
        <Aside />

        <MainContent>
          <MainWrapper>
            <div className="header">
              <button onClick={backToHome}>
                <FiChevronLeft size={20} color="#1c1c1c" />
              </button>
              <h1>Registar Funcionário</h1>
            </div>

            <Forms>
              <LeftAside>
                <label>Nome</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={employee.name}
                  onChange={(e) =>
                    setEmployee({ ...employee, name: e.target.value })
                  }
                />
                <label>Email</label>
                <Input
                  type="email"
                  size={100}
                  space={5}
                  value={employee.email}
                  onChange={(e) =>
                    setEmployee({ ...employee, email: e.target.value })
                  }
                />
                <label>Bilhete de Identidade</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={employee.bi}
                  onChange={(e) =>
                    setEmployee({ ...employee, bi: e.target.value })
                  }
                />

                <label>Especialidade</label>
                <Input
                  type="text"
                  size={100}
                  space={5}
                  value={employee.profissao}
                  onChange={(e) =>
                    setEmployee({ ...employee, profissao: e.target.value })
                  }
                />
              </LeftAside>

              <RightAside>
                <label>Telefone</label>
                <Input
                  size={100}
                  space={5}
                  value={employee.phone}
                  onChange={(e) =>
                    setEmployee({ ...employee, phone: e.target.value })
                  }
                />
                <label>Data de Nascimento</label>
                <Input
                  type="date"
                  size={100}
                  space={5}
                  value={employee.date_nascimento}
                  onChange={(e) =>
                    setEmployee({
                      ...employee,
                      date_nascimento: e.target.value,
                    })
                  }
                />
                <label>Contacto de emergência</label>
                <Input
                  size={100}
                  space={5}
                  value={employee.emergency_phone}
                  onChange={(e) =>
                    setEmployee({
                      ...employee,
                      emergency_phone: e.target.value,
                    })
                  }
                />
                <label>Tipo</label>
                <Select
                  options={[
                    { value: "Administrador", label: "Administrador" },
                    { value: "Instrutor", label: "Instrutor" },
                    { value: "Outro", label: "Outro" },
                  ]}
                  ref={selectInputRef}
                  isSearchable={false}
                  isMulti
                  placeholder={"Selecione o tipo"}
                  onChange={handleChange}
                />
              </RightAside>
            </Forms>

            <div
              className="button_container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                // position: "absolute",
                // bottom: 180,
                // left: 0,
                // right: 60,
              }}
            >
              <Button
                loading={loading}
                onClick={createEmployee}
                style={{ width: "20%" }}
              >
                {loading ? (
                  <FaSpinner color="#FFF" size={14} />
                ) : (
                  <span>Registar</span>
                )}
              </Button>
            </div>
          </MainWrapper>
        </MainContent>
      </MainContainer>
    </Container>
  );
}

export default RegisterFuncionario;
