import styled from "styled-components";

import colors from "../../styles/colors";

export const Container = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.borderColor};
  height: 45px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  border-radius: 4px;

  input {
    width: 100%;
    border-radius: 0;
    color: #555;
    flex: 1;
    font-size: 15px;
    border: 0;
    background: transparent;
    outline: none;
    &::placeholder {
      font-size: 14px;
      color: #555;
      font-weight: 100;
      opacity: 0.8;
    }
  }
`;
